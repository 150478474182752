import { useState, useEffect } from "react";
import axios from "axios";
import { formatAmount } from "../../../utils";
import Alert from "../../../components/Alert";
import Modal from "../../../components/Modal/regular";
import PaymentModal from "../../../components/Modal/payment.modal";
import formStyles from "../../../Identity-management-individual/style.module.css";
import styles from "../style.module.css";

const CouponSale = () => {
	const [vendorESBN, setVendorESBN] = useState("");
	const [fetchingESBNDetails, setFetchingESBNDetails] = useState(false);
	const [ESBNDetails, setESBNDetails] = useState(null);
	const [vendorName, setVendorName] = useState("");
	const [phoneNumber, setPhoneNUmber] = useState("");
	const [email, setEmail] = useState("");
	const [noOfBundles, setNoOfBundles] = useState("");
	const [address, setAddress] = useState("");
	const [calculationResult, setCalculationResult] = useState(null);
	const [calculating, setCalculating] = useState(false);
	const [generating, setGenerating] = useState(false);
	const [bill, setBill] = useState(null);
	const [billNumber, setBillNumber] = useState(null);
	const [billModalShowing, setBillModalShowing] = useState(false);
	const [paymentModalShowing, setPaymentModalShowing] = useState(false);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const calculatePrice = async (e) => {
		e.preventDefault();

		try {
			setCalculating(true);

			const data = await axios
				.get(`/calckoupon/${noOfBundles}`, {
					headers: {
						secureddata: "VGhpc2lzTm9ybGljcw=="
					}
				})
				.then((res) => (res.data || {}).data);

			setCalculationResult(data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setCalculating(false);
		}
	};

	const generateBill = async () => {
		try {
			setGenerating(true);

			const billNumber = await axios
				.post(
					`/purchasetag`,
					{
						vendoresbn: vendorESBN,
						vendorname: vendorName,
						vendoraddr: address,
						vendorFoneNo: phoneNumber,
						vendorEmail: email,
						bundleNo: +noOfBundles
					},
					{
						headers: {
							secureddata: "VGhpc2lzTm9ybGljcw=="
						}
					}
				)
				.then((res) => (res.data || {}).data.billno);

			setBillNumber(billNumber);
			const bill = await axios
				.get(`/mdanotice/${billNumber}/04/1`)
				.then((res) => res.data || {});

			setBillModalShowing(true);
			setBill(bill);
		} catch (error) {
			setCalculationResult(null);
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setGenerating(false);
		}
	};

	const fetchESBNDetails = async () => {
		setFetchingESBNDetails(true);

		try {
			const res = await axios
				.get(`/users/anssid?anssid=${vendorESBN}`)
				.then((res) => (res.data || {}).data);

			setESBNDetails(res);
			setVendorName(
				res.accounttype === "individual"
					? `${res.firstname} ${res.middlename} ${res.surname}`
					: res?.name
			);
			setPhoneNUmber(
				res.accounttype === "individual"
					? res.phonenumber
					: res.phoneNumber
			);
			setEmail(res.email);
			setAddress(
				res.accounttype === "individual"
					? res.residentialaddress
					: res.headOfficeAddress
			);
		} catch (error) {
			setESBNDetails(null);
			setVendorName("");
			setPhoneNUmber("");
			setEmail("");
			setAddress("");
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message ||
						error.response.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setFetchingESBNDetails(false);
		}
	};

	useEffect(() => {
		if (vendorESBN.length >= 10) fetchESBNDetails();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vendorESBN]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			5000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{billModalShowing && (
				<Modal showing={billModalShowing} toggle={setBillModalShowing}>
					<div
						dangerouslySetInnerHTML={{
							__html: bill
						}}
					/>
					<div className={styles.sale__bill_btns}>
						<button className="secondary__btn">Print</button>
						<button
							className="primary__btn"
							onClick={() => {
								setBillModalShowing(false);
								setPaymentModalShowing(true);
							}}
						>
							Pay Now
						</button>
					</div>
				</Modal>
			)}
			{paymentModalShowing && (
				<PaymentModal
					showing={paymentModalShowing}
					toggle={setPaymentModalShowing}
					billNumber={billNumber}
					goBackTo={"/coupon/purchase/records"}
				/>
			)}
			<h2 className={styles.header}>Vendor's Information</h2>
			<form
				className={formStyles.columned__form}
				onSubmit={calculatePrice}
			>
				<div className={formStyles.double__inputs}>
					<div>
						<label htmlFor="vendorESBN">
							Vendor ESBN <span className="required">*</span>
						</label>
						<input
							id="vendorESBN"
							name="vendorESBN"
							type="number"
							value={vendorESBN}
							onChange={(e) => {
								setVendorESBN(e.target.value);
							}}
							placeholder="enter ESBN"
							disabled={calculating}
							required
						/>
						{fetchingESBNDetails && (
							<p
								style={{
									fontSize: "12px",
									marginTop: "-25px",
									color: "#9e9e9e"
								}}
							>
								Fetching details...
							</p>
						)}
					</div>
				</div>
				<div className={formStyles.double__inputs}>
					<div>
						<label htmlFor="vendorName">
							Vendor's Name <span className="required">*</span>
						</label>
						<input
							id="vendorName"
							name="vendorName"
							value={vendorName}
							onChange={(e) => {
								setVendorName(e.target.value);
							}}
							placeholder="enter vendor's name"
							disabled
							required
						/>
					</div>
					<div>
						<label htmlFor="phoneNumber">Phone Number</label>
						<input
							id="phoneNumber"
							name="phoneNumber"
							type="tel"
							value={phoneNumber}
							onChange={(e) => {
								setPhoneNUmber(e.target.value);
							}}
							placeholder="enter ESBN"
							disabled
						/>
					</div>
				</div>
				<div className={formStyles.double__inputs}>
					<div>
						<label htmlFor="email">Email</label>
						<input
							id="email"
							name="email"
							type="email"
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
							placeholder="enter email"
							disabled
						/>
					</div>
					<div>
						<label htmlFor="noOfBundles">
							Number of Bundles{" "}
							<span className="required">*</span>
						</label>
						<input
							id="noOfBundles"
							type="number"
							name="noOfBundles"
							value={noOfBundles}
							onChange={(e) => {
								setNoOfBundles(e.target.value);
							}}
							placeholder="enter the number of bundles to purchase"
							disabled={calculating}
							required
						/>
					</div>
				</div>
				<label>Address</label>
				<input
					id="address"
					name="address"
					value={address}
					onChange={(e) => {
						setAddress(e.target.value);
					}}
					placeholder="enter your address"
					disabled
				/>
				{!calculationResult && (
					<button
						className={`primary__btn ${styles.sale__submit_btn}`}
						type="submit"
						disabled={
							!vendorESBN ||
							!vendorName ||
							!noOfBundles ||
							!ESBNDetails
						}
					>
						{calculating ? "Calculating..." : "Calculate Amount"}
					</button>
				)}
			</form>
			{calculationResult && (
				<div style={{ maxWidth: "736px", marginRight: "16px" }}>
					<div className={styles.calculation__details}>
						<div>
							<span>Total Coupon: &nbsp;</span>
							<span>
								{formatAmount(
									calculationResult?.totCouponPieces,
									true
								)}{" "}
								Pieces
							</span>
						</div>
						<div>
							<span>Total Amount: &nbsp;</span>
							<span>
								₦{formatAmount(calculationResult?.totalAmount)}
							</span>
						</div>
						<div>
							<span>Total Commission: &nbsp;</span>
							<span>
								₦{formatAmount(calculationResult?.TotalComm)}
							</span>
						</div>
					</div>
					<button
						className={`primary__btn ${styles.sale__submit_btn}`}
						onClick={generateBill}
					>
						{generating ? "Generating..." : "Generate Bill"}
					</button>
				</div>
			)}
		</section>
	);
};

export default CouponSale;
