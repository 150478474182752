/* eslint-disable eqeqeq */
import { formatAmount } from "../../utils";
import Pagination from "./Pagination";
import styles from "./style.module.css";

const Table = ({
	headers,
	data = [],
	full,
	total = data?.length,
	pageSize = 20,
	onRowClick,
	billNoOfAmountPaidShowing,
	setBillNoOfAmountPaidShowing,
	amountPaid,
	onProfile,
	onPageChanged,
	onEdit,
	onView,
	onPay,
	onOthers,
	onH1,
	onDelete,
	onToggleActivation,
	onAddToPrint,
	onRemoveFromPrint,
	onPayNow,
	printArray,
	showingTotal = true
}) => {
	return (
		<>
			<div className={styles.table__wrapper}>
				<table className={styles.table}>
					<thead>
						<tr className={styles.table__row}>
							{headers.map((header, i) => {
								if (full) {
									return <th key={i}>{header[0]}</th>;
								} else
									return i > 4 ? null : (
										<th key={i}>{header[0]}</th>
									);
							})}
							{(onEdit ||
								onView ||
								onPay ||
								onOthers ||
								onH1 ||
								onDelete ||
								(onAddToPrint && onRemoveFromPrint) ||
								onProfile ||
								onOthers ||
								onPayNow) && <th>Action</th>}
						</tr>
					</thead>
					<tbody>
						{data?.map((each, i) => (
							<tr
								className={styles.table__row}
								key={i}
								onClick={() =>
									onRowClick ? onRowClick(each) : null
								}
							>
								{headers.map((header, i) => {
									const value = header[2]
										? header[2](each[header[1]])
										: each[header[1]];
									if (full) {
										return (
											<td
												key={i}
												className={
													value
														?.toString()
														.toLowerCase() ===
													"pending"
														? styles.pending
														: value ===
															  "Pending Approval"
															? styles.pending__approval
															: value ===
																		"Approved" ||
																  value ===
																		"approved"
																? styles.approved
																: value ===
																			"Denied" ||
																	  value ===
																			"Cancelled" ||
																	  value ===
																			"Revoked" ||
																	  value ===
																			"Declined" ||
																	  value ===
																			"Deleted" ||
																	  value ===
																			"Rejected" ||
																	  value ===
																			"rejected"
																	? styles.denied
																	: null
												}
											>
												{header[1] === "filedStatus" ? (
													<p
														className={`
													${styles.filed__status__row}
													${value === "filed" ? styles.filed : styles.not__filed}
												`}
													>
														{value != undefined
															? value
															: "Not Filed"}
													</p>
												) : value != undefined ? (
													value
												) : (
													"N/A"
												)}
											</td>
										);
									} else
										return i > 4 ? null : (
											<td
												key={i}
												className={
													value === "Pending"
														? styles.pending
														: value === "Approved"
															? styles.approved
															: value === "Denied"
																? styles.denied
																: null
												}
											>
												{value != undefined
													? value
													: "N/A"}
											</td>
										);
								})}
								{(onEdit ||
									onView ||
									onOthers ||
									onH1 ||
									onDelete ||
									(onAddToPrint && onRemoveFromPrint) ||
									onToggleActivation) && (
									<td>
										{onView && (
											<>
												<button
													type="button"
													className={styles.view__btn}
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														onView(each);
													}}
												>
													View
												</button>
												<br />
											</>
										)}
										{onEdit &&
											each.ProcessStatus !==
												"Revoked" && (
												<>
													<button
														type="button"
														className={
															styles.edit__btn
														}
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															onEdit(each);
														}}
													>
														Edit
													</button>
													<br />
												</>
											)}
										{onPay &&
											each.ProcessStatus !==
												"Revoked" && (
												<>
													<button
														type="button"
														className={
															styles.edit__btn
														}
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															onPay(each);
														}}
													>
														Pay With
													</button>
													<br />
												</>
											)}
										{onOthers && (
											<button
												type="button"
												className={styles.revoke__btn}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													onOthers(each);
												}}
											>
												Revoke
											</button>
										)}
										{onH1 &&
											each.FilingStatus ===
												"approved" && (
												<button
													type="button"
													className={
														styles.revoke__btn
													}
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														onOthers(each);
													}}
												>
													Revoke
												</button>
											)}
										{onH1 &&
											each.FilingStatus ===
												"approved" && (
												<button
													type="button"
													className={
														styles.h1__button
													}
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														onH1(each);
													}}
												>
													Download H1
												</button>
											)}
										{onDelete && (
											<button
												type="button"
												className={styles.revoke__btn}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													onDelete(each);
												}}
											>
												Delete
											</button>
										)}
										{onPayNow &&
											each.diff > 0 &&
											parseInt(
												each.ApproveFlg ||
													each.approvalStatus
											) !== 1 &&
											parseInt(
												each.ApproveFlg ||
													each.approvalStatus
											) !== 2 &&
											parseInt(
												each.ApproveFlg ||
													each.approvalStatus
											) !== 4 && (
												<button
													type="button"
													className={
														styles.print__btn
													}
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														onPayNow(each);
													}}
												>
													Pay Now
												</button>
											)}
										{onToggleActivation && (
											<button
												type="button"
												className={styles.revoke__btn}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													onToggleActivation(each);
												}}
											>
												{each?.Deactivated
													? "Activate"
													: "Deactivate"}
											</button>
										)}
										{onAddToPrint && onRemoveFromPrint && (
											<button
												type="button"
												className={
													printArray?.includes(
														each.BillNumber
													)
														? styles.removeFromPrint
														: styles.print__btn
												}
												onClick={
													printArray?.includes(
														each.BillNumber
													)
														? (e) => {
																e.preventDefault();
																e.stopPropagation();
																onRemoveFromPrint(
																	each
																);
															}
														: (e) => {
																e.preventDefault();
																e.stopPropagation();
																onAddToPrint(
																	each
																);
															}
												}
											>
												{printArray?.includes(
													each.BillNumber
												)
													? "Remove From Print"
													: "Add To Print"}
											</button>
										)}
									</td>
								)}
								{onRowClick &&
									billNoOfAmountPaidShowing ===
										(each.AssessmentCode ||
											onH1 ||
											each.BillNumber ||
											each.nb) && (
										<td
											className={
												styles.amountPaid__tooltip
											}
										>
											{amountPaid ? (
												<>
													<button
														onClick={(e) => {
															e.stopPropagation();
															setBillNoOfAmountPaidShowing(
																""
															);
														}}
													>
														x
													</button>
													Amount Paid: ₦
													{formatAmount(amountPaid)}
												</>
											) : (
												"...."
											)}
										</td>
									)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{full && (
				<footer className={styles.table__footer}>
					{showingTotal && (
						<p>
							Displaying {data?.length} out of {total}
						</p>
					)}
					{total && (
						<Pagination
							totalRecords={total}
							pageLimit={pageSize}
							pageNeighbours={1}
							onPageChanged={onPageChanged}
						/>
					)}
				</footer>
			)}
		</>
	);
};

export default Table;
