import React, { useEffect, useState } from "react";
import styles from "../../Identity-management-individual/style.module.css";
import styles1 from "./style.module.css";
import styles2 from "../../Direct-assessment/Create-direct-assessment/style.module.css";
import Spinner from "../../components/spinner";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Alert from "../../components/Alert";

export const EditSetup = () => {
	const [loading, setLoading] = useState(false);
	let { state } = useLocation();
	const [setupType, setSetupType] = useState(state?.setupType ?? "income");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const [income, setIncome] = useState({
		code: "",
		description: ""
	});

	const [payment, setPayment] = useState({
		code: "",
		description: ""
	});

	const handleIncomeChange = (e) => {
		const { name, value } = e.target;
		setIncome({ ...income, [name]: value });
	};

	const handlePaymentChange = (e) => {
		const { name, value } = e.target;
		setPayment({ ...payment, [name]: value });
	};

	useEffect(() => {
		if (state?.setupType === "income") {
			setIncome({
				code: state?.Code,
				description: state?.Description
			});
		} else {
			setPayment({
				code: state?.Code,
				description: state?.Description
			});
		}
	}, [state]);

	const submit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const res = await axios
				.put(
					setupType === "income"
						? `hniincome/${state?.Code}`
						: `hniprepayment/${state?.Code}`,
					{
						Code: income?.code,
						Description: income?.description,
						Deactivated: false
					}
				)
				.then((res) => res.data);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res?.msg
			});
			setLoading(false);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.errors?.[0] ||
						error.response.data?.msg
				});
			}
			setLoading(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<form className={styles.columned__form} onSubmit={submit}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={styles2.radio__button_div}>
				<div>
					<div>
						<input
							id="incomeSetup"
							type="radio"
							value="income"
							disabled={state?.setupType}
							checked={setupType === "income"}
							onChange={(e) => {
								setSetupType(e.target.value);
							}}
						/>
						<label
							className={styles.input__label}
							htmlFor="incomeSetup"
						>
							Income
						</label>
					</div>
					<div>
						<input
							id="paymentSetup"
							type="radio"
							value="payment"
							disabled={state?.setupType}
							checked={setupType === "payment"}
							onChange={(e) => {
								setSetupType(e.target.value);
							}}
						/>
						<label
							className={styles.input__label}
							htmlFor="paymentSetup"
						>
							Payment
						</label>
					</div>
				</div>
			</div>

			{setupType === "income" ? (
				<>
					<h3>Income</h3>
					<br />
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="code"
							>
								Code
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								id="code"
								name="code"
								onChange={handleIncomeChange}
								placeholder="enter code"
								value={income?.code}
							/>
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="description"
							>
								Description/Name
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								name="description"
								onChange={handleIncomeChange}
								id="description"
								placeholder="enter description/ name"
								value={income?.description}
							/>
						</div>
					</div>
					<div className={styles1.add_button__wrapper}>
						<button
							disabled={loading}
							type="submit"
							className={[
								"primary__btn",
								styles1.create__button,
								styles.primary__button,
								loading ? styles.loading__button : ""
							].join(" ")}
						>
							Create
							{loading && <Spinner />}
						</button>
					</div>
				</>
			) : (
				<>
					<h3>Prepayments</h3>
					<br />
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="code"
							>
								Code
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								id="code"
								name="code"
								onChange={handlePaymentChange}
								placeholder="enter code"
								value={payment?.code}
							/>
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="description"
							>
								Description/Name
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								name="description"
								onChange={handlePaymentChange}
								id="description"
								placeholder="enter description/ name"
								value={payment?.description}
							/>
						</div>
					</div>
					<div className={styles1.add_button__wrapper}>
						<button
							disabled={loading}
							type="submit"
							className={[
								"primary__btn",
								styles1.create__button,
								styles.primary__button,
								loading ? styles.loading__button : ""
							].join(" ")}
						>
							{loading ? "Saving..." : "Save"}
							{loading && <Spinner />}
						</button>
					</div>
				</>
			)}
		</form>
	);
};
