import { useState, useEffect } from "react";
import axios from "axios";
import Alert from "../../../components/Alert";
import formStyles from "../../../Identity-management-individual/style.module.css";
import buttonStyle from "../../Certificate/style.module.css";

const CouponGeneration = () => {
	const userInfo = JSON.parse(localStorage.getItem("user_info"));
	const [noOfCoupons, setNoOfCoupons] = useState("");
	const [generating, setGenerating] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	async function generateCoupons(e) {
		e.preventDefault();
		setGenerating(true);

		try {
			const res = await axios
				.post(
					"/coupon/generatecouponserials",
					{
						totSerials: noOfCoupons,
						createdby: userInfo?.email
					},
					{
						headers: {
							secureddata: "VGhpc2lzTm9ybGljcw=="
						}
					}
				)
				.then((res) => res.data);

			setNoOfCoupons("");
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.msg
			});
		} catch (error) {
			if (error.response) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message ||
						error.response?.data.err.message
				}));
			} else if (error.request) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		} finally {
			setGenerating(false);
		}
	}

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			5000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form
				className={formStyles.columned__form}
				onSubmit={generateCoupons}
			>
				<div className={formStyles.double__inputs}>
					<div>
						<label htmlFor="noOfCoupons">Number of coupons</label>
						<input
							type="number"
							id="noOfCoupons"
							value={noOfCoupons}
							onChange={(e) => {
								setNoOfCoupons(e.target.value);
							}}
							placeholder="enter number of coupons to be generated"
						/>
					</div>
				</div>
				<button
					type="submit"
					className={`primary__btn ${buttonStyle.breakdown__btn}`}
					disabled={!noOfCoupons || generating}
				>
					{generating ? "Generating..." : "Generate Coupons"}
				</button>
			</form>
		</div>
	);
};

export default CouponGeneration;
