import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import {
	formatAmount,
	getStatusText,
	formatDate,
	exportExcel,
	remapObj
} from "../../../utils";
import BackButton from "../../../components/goBackButton";
import Table from "../../../components/Table";
import Alert from "../../../components/Alert";
import styles from "../style.module.css";

const DownloadCouponView = () => {
	const { state: locationState } = useLocation();

	const [loading, setLoading] = useState(false);
	const [breakdown, setBreakdown] = useState([]);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const headers = [
		["Batch Number", "TagId"],
		["Serial Number", "SerialNo"],
		["Inventory ID", "InventoryId"],
		["Coupon Count", "GlobalCouponCount"],
		["Total Coupon Not Used", "TotalCouponNotUsed"],
		["Total Coupon Used", "TotalCouponUsed"],
		["Completed", "CompletedUsageFlg", (data) => Boolean(data)],
		[
			"Completed Use Date",
			"CompletedUsedDate",
			(date) => (date ? formatDate(date) : "N/A")
		]
	];

	const getBatch =
		({ currentPage = 1, pageSize = 20 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);

			let query = `page=${currentPage}&pageSize=${pageSize}&`;

			try {
				let breakdown = await axios
					.get(
						`/getglobalcouponitemsdown/${locationState?.couponglobalid}?${query}`,
						{
							headers: {
								secureddata: "VGhpc2lzTm9ybGljcw=="
							}
						}
					)
					.then((res) => res.data.data.data);
				setBreakdown(
					breakdown?.map((tag) => ({
						...tag,
						CompletedUsageFlg: `${tag?.CompletedUsageFlg}`
					}))
				);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setLoading(false);
			}
		};

	function downloadTable() {
		exportExcel(
			remapObj(breakdown, headers),
			headers,
			`${locationState.couponglobalid} Coupon details.xlsx`
		);
	}

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	useEffect(() => {
		getBatch()();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<BackButton />
			<div className={styles.coupon__details}>
				<div>
					<span className={styles.key}>Coupon ID: </span>
					<span className={styles.value}>
						{locationState?.couponglobalid}
					</span>
				</div>
				<div>
					<span className={styles.key}>Coupon Amount: </span>
					<span className={styles.value}>
						₦ {formatAmount(locationState?.totalcouponamt)}
					</span>
				</div>
				<div>
					<span className={styles.key}>Generated By: </span>
					<span className={styles.value}>
						{locationState?.inputterid}
					</span>
				</div>
				<div>
					<span className={styles.key}>Status: </span>
					<span
						className={`${styles.value} ${
							getStatusText(
								locationState?.approveflg
							).toLowerCase() === "pending"
								? styles.pending
								: getStatusText(
											locationState?.approveflg
									  ).toLowerCase() === "pending approval"
									? styles.pending__approval
									: getStatusText(
												locationState?.approveflg
										  ).toLowerCase() === "approved"
										? styles.approved
										: styles.denied
						}`}
					>
						{getStatusText(locationState?.approveflg)}
					</span>
				</div>
				<div>
					<span className={styles.key}>Date Issued: </span>
					<span className={styles.value}>
						{new Date(locationState?.createdate).toDateString()}
					</span>
				</div>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					margin: "40px 0px 24px"
				}}
			>
				<h2 className={styles.table__header} style={{ margin: "0" }}>
					Tags Details
				</h2>
				<button
					className="primary__btn"
					style={{
						padding: "0 20px"
					}}
					onClick={() => downloadTable()}
					disabled={!breakdown.length}
				>
					Download Table
				</button>
			</div>
			{loading ? (
				<p style={{ textAlign: "center" }}>Loading...</p>
			) : (
				<>
					<Table
						headers={headers}
						data={breakdown}
						full
						pageSize={20}
					/>
				</>
			)}
		</div>
	);
};

export default DownloadCouponView;
