/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "../../../Identity-management-individual/style.module.css";
import Spinner from "../../../components/spinner";
import Receipt from "../../ReceiptPymtRef/receipt";
import Alert from "../../../components/Alert";
import styles1 from "./style.module.css";
import styles2 from "../../../Direct-assessment/Create-direct-assessment/style.module.css";
import styles3 from "../../../Identity-management-individual/style.module.css";

const user = JSON.parse(localStorage.getItem("user_info"));
const ViewBill = () => {
	const [data, setData] = useState({});
	const [invoice, setInvoice] = useState("");
	const [loading, setLoading] = useState(false);
	const [subscription, setSubcription] = useState("yearly");
	const [fiscalyr, setFiscalYear] = useState("");
	const [selectMonth, setSelectedMonth] = useState("");
	const [taxoffice, setTaxOffice] = useState("");
	const [taxzone, setTaxZone] = useState("");
	const [taxlga, setTaxLGA] = useState("");
	const [phoneno, setPhoneNumber] = useState("");
	const [shopnumber, setShopNumber] = useState("");
	const [discount, setDiscount] = useState("");
	const [discountReason, setDiscountReason] = useState("");
	const [outstandingReason, setOutstandingReason] = useState("");
	const [outstand, setOutStanding] = useState("");
	const [email, setEmail] = useState("");
	const [residentialaddress, setResidentialaddress] = useState("");
	const [ownersesbn, setownersesbn] = useState("");
	const [revenue, setRevenue] = useState("");
	const [zones, setZones] = useState([]);
	const [lgas, setLGAs] = useState([]);
	const [taxoffices, setTaxOffices] = useState([]);
	const [revenues, setRevenues] = useState([]);
	const [ownersname, setownersname] = useState("");
	const [finishBill, setFinishBill] = useState("0");
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const [profileDetailsArray, setProfileDetailsArray] = React.useState([
		{
			amount: "",
			PIDname: " ",
			houseNo: " ",
			HouseStr: " ",
			houseArea: " ",
			houseLGA: " ",
			houseZone: " ",
			revenuedescription: "",
			profile: "",
			profilecategory: "",
			billtype: "",
			propertyid: " ",
			qty: "1",
			month: "1",
			revenue: ""
		}
	]);
	const [profileDetails, setProfileDetails] = React.useState({
		amount: "",
		PIDname: " ",
		houseNo: " ",
		HouseStr: " ",
		houseArea: " ",
		houseLGA: " ",
		houseZone: " ",
		revenuedescription: "",
		profile: "",
		profilecategory: "",
		billtype: "",
		propertyid: " ",
		qty: "1",
		month: "1",
		revenue: ""
	});

	let { state } = useLocation();

	// //if in editing mode, fill details
	// useEffect(() => {
	// 	if (historyState?.edit || historyState?.view) {

	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const updateAmount = (value, i) => {
		const newProfilesArray = profileDetailsArray.map((child, index) => {
			return index === Number(i) ? { ...child, amount: value } : child;
		});
		setProfileDetailsArray(newProfilesArray);
	};

	const handleProfileInArrayChange = (e, i) => {
		const value = e.target.value;
		const name = e.target.name;

		// console.log({ name, value });
		if (name === "revenue" && value !== "") {
			setRevenue(name);
		}

		const newProfilesArray = profileDetailsArray.map((child, index) => {
			return index === i ? { ...child, [name]: value } : child;
		});

		setProfileDetailsArray(newProfilesArray);
	};

	const allBilledAmount = useMemo(() => {
		const allAmount = profileDetailsArray.reduce(
			(accumulator, currentValue) =>
				accumulator +
				Number(currentValue.amount) *
					Number(currentValue.qty) *
					Number(currentValue.month),
			0
		);
		const newTotalAmount =
			Number(allAmount) > 0 &&
			Number(allAmount) - Number(discount) + Number(outstand) >= 0
				? Number(allAmount) - Number(discount) + Number(outstand)
				: Number(allAmount);
		// return subscription === 'yearly' ? newTotalAmount.toFixed(2) : (newTotalAmount/12).toFixed(2) ;
		return newTotalAmount.toFixed(2);
	}, [profileDetailsArray, outstand, discount, subscription]);

	const handleDiscount = (value) => {
		if (allBilledAmount < value) {
			setAlert({
				showing: true,
				type: "error",
				message: "Discount cannot be higher than amount."
			});
		} else {
			setDiscount(value);
			setAlert({
				showing: false,
				type: null,
				message: ""
			});
		}
	};

	const fetchCurrentBill = useCallback(async () => {
		const res = await axios
			.get(`billing/${state.coy}/${state.userId}`)
			.then((res) => (res.data || {}).data?.result);

		if (res?.BillNumber) {
			fetchInvoice(res?.BillNumber);
		}
		setData(res);
		console.log("data => ", data);
	}, [state.userId, state.coy]);

	// useEffect(() => {
	// 	fetchCurrentBill();
	// }, [fetchCurrentBill]);

	const fetchInvoice = async (billNumber) => {
		setLoading(true);
		await axios
			.get(`mdanotice/${billNumber}/${user?.mdaModuleCoy}`)
			.then((res) => {
				setInvoice(res?.data);
			})
			.catch((error) => console.log({ error }))
			.finally(() => setLoading(false));
	};

	const fetchCurrentBill2 = async (coyz, billNo) => {
		setLoading(true);
		await axios
			.get(`billing/${coyz}/${billNo}`)
			.then((res) => {
				if (res.data?.data?.result?.BillNumber) {
					fetchInvoice(res?.data?.data?.result?.BillNumber);
				}
				setData(res?.data?.data?.result);
				console.log("data => ", res.data?.data?.result);
			})
			.catch((error) => console.log({ error }))
			.finally(() => {
				setFinishBill("1");
				setLoading(false);
			});
	};

	useEffect(() => {
		fetchCurrentBill2(state.coy, state.userId);
	}, [finishBill === "1"]);

	// Zone
	useEffect(() => {
		const fetchZones = async () => {
			const res = await axios
				.get(`settings/zones/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setZones(res);
		};
		fetchZones();
	}, []);

	// LGA
	useEffect(() => {
		const fetchLGAs = async () => {
			const res = await axios
				.get(`settings/lgass/${user?.mdaModuleCoy}`)
				.then((res) => (res.data || {}).data);
			setLGAs(res);
		};
		fetchLGAs();
	}, []);

	// Tax Office
	useEffect(() => {
		const fetchTaxOffices = async () => {
			const res = await axios
				.get(`/settings/assesstaxoffice`)
				.then((res) => (res.data || {}).data);
			setTaxOffices(res);
		};
		fetchTaxOffices();
	}, []);

	const printInvoiceFile = (e) => {
		e.preventDefault();

		if (!!invoice) {
			const w = window.open();
			w.document.write(invoice);
			w.document.close();
			w.onload = () => {
				w.focus();
				w.print();
			};

			w.onafterprint = () => {
				w.close();
			};
		} else {
			return;
		}
	};

	return (
		<>
			{/* <form onSubmit={printInvoiceFile}> */}
			<form
				className={styles3.columned__form}
				onSubmit={printInvoiceFile}
			>
				{alert.showing && (
					<Alert text={alert.message} type={alert.type} />
				)}
				<div className={styles2.radio__button_div}>
					<span className={styles1.input__label}>
						Bill is Scheduled for
					</span>
					<div>
						<div>
							<input
								id="yearlySchedule"
								disabled
								type="radio"
								value="yearly"
								checked={data?.Year_Mth_Flg === "1"}
							/>
							<label
								className={styles3.input__label}
								htmlFor="yearlySchedule"
							>
								Yearly
							</label>
						</div>
						<div>
							<input
								id="monthlySchedule"
								disabled
								type="radio"
								value="monthly"
								checked={data?.Year_Mth_Flg === "2"}
							/>
							<label
								className={styles3.input__label}
								htmlFor="monthlySchedule"
							>
								Monthly
							</label>
						</div>
					</div>
				</div>

				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="selectyear"
						>
							Bill Year (Fiscal Year)
						</label>
						<select
							id="selectYear"
							disabled
							value={data?.fiscalYear ?? ""}
							required
						>
							<option value="">Select</option>
							<option>2013</option>
							{new Array(new Date().getFullYear() + 1 - 2013)
								.fill(0)
								.map((_, i) => (
									<option key={i}>{2013 + i + 1}</option>
								))}
						</select>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="selectmonth"
						>
							Bill Month (Fiscal Month)
						</label>
						<select
							id="selectmonth"
							value={data?.fiscalMonth ?? ""}
							disabled
						>
							{[...Array(12)].map((_, index) => (
								<option
									key={index}
									value={new Intl.DateTimeFormat("en", {
										month: "long"
									}).format(new Date(2000, index, 1))}
								>
									{new Intl.DateTimeFormat("en", {
										month: "long"
									}).format(new Date(2000, index, 1))}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="taxlga">
							Bill from which Local Government Area(LGA)
						</label>
						<select
							id="taxlga"
							name="taxlga"
							disabled
							value={data?.TaxLGA ?? ""}
						>
							<option value="">--Select Billing LGA--</option>
							{lgas.map((lgatax, index) => (
								<option
									value={lgatax.Description}
									zonecode={lgatax.Code}
									index={index}
								>
									{lgatax.Description}
								</option>
							))}
						</select>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="taxzone"
						>
							Bill from which Zone?
						</label>
						<select
							id="taxzone"
							name="taxzone"
							disabled
							value={data?.TaxZone ?? ""}
						>
							<option value="">--Select Billing Zone--</option>
							{zones?.map((tzone, index) => (
								<option
									value={tzone.Description}
									zonecode={tzone.Code}
									index={index}
								>
									{tzone.Description}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="taxoffice"
						>
							Bill from which Tax Office?
						</label>
						<select
							id="taxoffice"
							name="taxoffice"
							disabled
							value={data?.TaxOffice ?? ""}
						>
							<option value="">
								--Select Billing Tax Office--
							</option>
							{taxoffices.map((tax, index) => (
								<option
									value={tax.Description}
									taxcode={tax.Code}
									index={index}
								>
									{tax.Description}
								</option>
							))}
						</select>
					</div>
				</div>
				<hr className={styles.divider} />
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="ownersesbn"
						>
							ESBN
						</label>
						<input
							type="text"
							disabled
							id="ownersesbn"
							value={data?.OwnersESBN ?? ""}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="ownersname"
						>
							Shop Owner's Name{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="text"
							id="ownersname"
							disabled
							value={data?.OwnersName ?? ""}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="residentialaddress"
						>
							Line of Business
						</label>
						<input
							type="text"
							id="residentialaddress"
							disabled
							value={data?.OwnersAddr ?? ""}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="shopnumber"
						>
							Shop Number
						</label>
						<input
							type="text"
							id="shopnumber"
							disabled
							value={data?.TagId ?? ""}
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="phonenumber"
						>
							Phone
						</label>
						<input
							type="text"
							id="phonenumber"
							disabled
							value={data?.OwnersPhone ?? ""}
						/>
					</div>
					<div>
						<label className={styles.input__label} htmlFor="email">
							Email
						</label>
						<input
							type="text"
							id="email"
							disabled
							value={data?.OwnerEmail ?? ""}
						/>
					</div>
				</div>
				<hr className={styles.divider} />
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="discount"
						>
							Discount
						</label>
						<input
							type="text"
							id="discount"
							disabled
							value={data?.Discount ?? ""}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="discountReason"
						>
							Reason for Discount
						</label>
						<input
							type="text"
							onChange={(e) => setDiscountReason(e.target.value)}
							id="discountReason"
							disabled
							value={data?.DiscountReason ?? ""}
						/>
					</div>
				</div>
				{/* femi ===> outstanding */}
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="outstanding"
						>
							Outstanding
						</label>
						<input
							type="text"
							id="outstanding"
							disabled
							value={data?.Outstanding ?? ""}
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="outstandingReason"
						>
							Reason for Outstanding
						</label>
						<input
							type="text"
							id="outstandingReason"
							disabled
							value={data?.outstandingReason ?? ""}
						/>
					</div>
				</div>
				{/* femi ===> outstanding */}
				<hr className={styles.divider} />

				{data?.TaxPayerBillsDetails?.length &&
					data?.TaxPayerBillsDetails?.map((billDetail, index) => (
						<React.Fragment key={`billDetail${index}`}>
							<br />
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="revenuedescription"
									>
										Property ID
									</label>
									<input
										type="text"
										name="propertyid"
										id="propertyid"
										disabled
										value={billDetail?.PropertyId ?? ""}
									/>
								</div>
							</div>
							<br />
							<h3>Address</h3>
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="houseNo"
									>
										No
									</label>
									<input
										type="text"
										name="houseNo"
										id="houseNo"
										disabled
										value={billDetail?.HouseNo ?? ""}
									/>
								</div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="HouseStr"
									>
										Street
									</label>
									<input
										type="text"
										name="HouseStr"
										id="HouseStr"
										disabled
										value={billDetail?.HouseStreet ?? ""}
									/>
								</div>
							</div>

							<br />
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="houseArea"
									>
										Area
									</label>
									<select
										id="houseArea"
										className={styles.input__long}
										name="houseArea"
										value={billDetail?.HouseArea ?? ""}
										disabled
									>
										<option>
											{billDetail?.HouseArea ?? ""}
										</option>
									</select>
								</div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="houseLGA"
									>
										LGA
									</label>
									<select
										id="houseLGA"
										className={styles.input__long}
										name="houseLGA"
										value={billDetail?.HouseLGA ?? ""}
										disabled
									>
										<option>
											{billDetail?.HouseLGA ?? ""}
										</option>
									</select>
								</div>
							</div>
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="HouseZone"
									>
										Zone
									</label>
									<select
										id="HouseZone"
										className={styles.input__long}
										name="HouseZone"
										value={billDetail?.HouseZone ?? ""}
										disabled
									>
										<option>
											{billDetail?.HouseZone ?? ""}
										</option>
									</select>
								</div>
							</div>

							<div className={styles.double__inputs}>
								<div>
									<br />

									<label
										className={styles.input__label}
										htmlFor="revenuedescription"
									>
										Description
									</label>
									<input
										type="text"
										name="revenuedescription"
										id="revenuedescription"
										disabled
										value={
											billDetail?.RevenueDescription ?? ""
										}
									/>
								</div>
							</div>

							<br />
							<br />
							<div className={styles.select__wrapper}>
								<div>
									<label htmlFor="billtype">
										Bill Type{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<select
										id="billtype"
										className={styles.input__medium}
										name="billtype"
										value={billDetail.billtype}
										disabled
									>
										<option>
											{billDetail?.BillType ?? ""}
										</option>
									</select>
								</div>
							</div>
							<div className={styles.select__wrapper}>
								<div>
									<label htmlFor="profile">
										Category{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<select
										id="profile"
										className={styles.input__medium}
										name="profile"
										value={billDetail.Profile}
										disabled
									>
										<option>
											{billDetail?.Profile ?? ""}
										</option>
									</select>
								</div>
								<div>
									<label htmlFor="profilecategory">
										Sub-Category{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<select
										id="profilecategory"
										className={styles.input__medium}
										name="profilecategory"
										value={billDetail?.ProfileCategory}
										disabled
									>
										<option>
											{billDetail?.ProfileCategory ?? ""}
										</option>
									</select>
								</div>
							</div>
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="qty"
									>
										How Many Units / Number of Occupants
									</label>
									<input
										type="number"
										name="qty"
										id="qty"
										disabled
										value={billDetail?.Quantity ?? ""}
									/>
								</div>
								<div>
									<label
										className={styles.input__label}
										htmlFor="month"
									>
										How Many Months
									</label>
									<input
										type="number"
										name="month"
										disabled
										id="month"
										placeholder="How many months?"
										value={billDetail?.monthNumber ?? ""}
									/>
								</div>
							</div>
							{billDetail?.LandMeasurement && (
								<>
									<div className={styles.double__inputs}>
										<div>
											<label
												className={styles.input__label}
												htmlFor="landMeasurement"
											>
												Land Measurement{" "}
												<span style={{ color: "red" }}>
													*
												</span>
											</label>
											<input
												type="text"
												name="landMeasurement"
												id="landMeasurement"
												disabled
												value={
													billDetail?.LandMeasurement ??
													""
												}
											/>
										</div>
									</div>
									<br />
								</>
							)}
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="amount"
									>
										Amount{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										name="amount"
										id="amount"
										disabled
										value={billDetail?.Amount ?? ""}
									/>
								</div>
								<div>
									<label htmlFor="revenue">
										Which Revenue is this bill Attached to?{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<select
										id="revenue"
										className={styles.input__medium}
										name="revenue"
										disabled
										value={billDetail?.RevenueCode ?? ""}
									>
										<option>--Select Revenue--</option>
										{revenues?.length &&
											revenues?.map((rev, index) => (
												<React.Fragment
													key={`${rev?.id}${index}`}
												>
													<option
														value={`${rev?.Code}`}
													>
														{rev?.Description}
													</option>
												</React.Fragment>
											))}
									</select>
								</div>
							</div>
						</React.Fragment>
					))}
				<br />
				<hr className={styles.divider} />
				<div className={styles.double__inputs}>
					<h4 style={{ fontWeight: "bold" }}>
						Total Amount Billed:{" "}
					</h4>
					&nbsp;
					<h4 style={{ fontWeight: 300 }}>
						&#8358;{data?.TotalAmtBilled}
					</h4>
				</div>
				<div className={styles.double__inputs}>
					<h4 style={{ fontWeight: "bold" }}>Total Amount: </h4>&nbsp;
					<h4 style={{ fontWeight: 300 }}>
						&#8358;{data?.GrandTotal}
					</h4>
				</div>

				<hr className={styles.divider} />
				{/* total and stuffs */}
				<footer className={styles.form__footer}>
					<button
						disabled={loading}
						type="submit"
						className={[
							"primary__btn",
							styles.primary__button,
							loading ? styles.loading__button : ""
						].join(" ")}
					>
						Download Invoice
						{loading && <Spinner />}
					</button>
				</footer>
			</form>
			{!!invoice && <Receipt data={invoice} loading={loading} />}
		</>
	);
};

export default ViewBill;
