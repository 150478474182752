import { useState, useEffect } from "react";
import axios from "axios";
import Alert from "../../../components/Alert";
import styles from "./style.module.css";

const CouponValidation = () => {
	const [method, setMethod] = useState("manually");
	const [couponSerialNumber, setCouponSerialNumber] = useState("");
	const [checking, setChecking] = useState(false);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const checkValidity = async (e) => {
		e.preventDefault();

		try {
			setChecking(true);

			const data = await axios
				.post(
					`/sellcoupon`,
					{
						coupSrl: couponSerialNumber
					},
					{
						headers: {
							secureddata: "VGhpc2lzTm9ybGljcw=="
						}
					}
				)
				.then((res) => res.data);
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: data.msg
			});
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setChecking(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			5000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form className={styles.form} onSubmit={checkValidity}>
				<p>Method</p>
				<div className={styles.radio__inputs}>
					<input
						name="method"
						id="QRCode"
						type="radio"
						value="QRCode"
						checked={method === "QRCode"}
						onChange={(e) => setMethod(e.target.value)}
						disabled
					/>
					<label htmlFor="QRCode">Scan QR Code</label>
					<input
						name="method"
						id="manually"
						type="radio"
						value="manually"
						checked={method === "manually"}
						onChange={(e) => setMethod(e.target.value)}
					/>
					<label htmlFor="manually">Enter Manually</label>
				</div>
				{method === "manually" && (
					<>
						<label htmlFor="serialNumber">
							Coupon Serial Number
						</label>
						<input
							id="serialNumber"
							className={styles.serial__number_input}
							value={couponSerialNumber}
							onChange={(e) =>
								setCouponSerialNumber(e.target.value)
							}
							placeholder="enter serial number"
						/>
						<button
							className={`primary__btn`}
							type="submit"
							disabled={
								method === "manually" && !couponSerialNumber
							}
						>
							{checking ? "Checking..." : "Check for Validity"}
						</button>
					</>
				)}
			</form>
		</section>
	);
};

export default CouponValidation;
