import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "../../style.module.css";
import style from "./style.module.css";
import style1 from "../../../../Identity-management-individual/style.module.css";
import downloadIcon from "../../../../assets/downloadFilingIcon.svg";
import signature from "../../../../assets/signature.svg";
import { ReactComponent as FileIcon } from "../../../../assets/fileIcon.svg";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Table from "../../../../components/Table";
import { H1Document } from "../../modals/h1Document";
import RegularModal from "../../../../components/Modal/regular";
import Alert from "../../../../components/Alert";
import PaymentModal from "../../../../components/Modal/payment.modal";
import { formatAmount, formatDate } from "../../../../utils";

const headers = [
	["Employee Name", "fullName"],
	["Employee ESBN", "eSBN"],
	["Months in Service", "monthsInService"],
	["Total Gross Salary (₦)", "annualGrossSalary", formatAmount],
	["Total Deductions (₦)", "annualCalcTotalRelief", formatAmount],
	["Annual Tax Due (₦)", "annualCalcTaxDue", formatAmount]
];

const recieptTableHeaders = [
	["Reciept Number", "ReceiptNo"],
	["Ammount (₦)", "Amount", formatAmount],
	["Payment Date", "PaymentDate", formatDate]
];

const userInfo = JSON.parse(localStorage.getItem("user_info"));

export const StepFive = ({
	updateTab = () => { },
	updateFilingData = () => { },
	filingData = null
}) => {
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingH1Data, setLoadingH1Data] = useState(false);
	const [agreement, setAgreement] = useState(false);
	const [anssid, setAnssid] = useState({});
	// eslint-disable-next-line
	const [H1File, setH1File] = useState(null);
	const [variant, setVariant] = useState(null);
	const [billNo, setBillNo] = useState(null);
	const [paymentModal, setPaymentModal] = useState(false);
	const [verifyingBillNo, setVerifyingBillNo] = useState(false);
	const [downloadH1ModalStatus, setDownloadH1ModalStatus] = useState({
		downloading: false,
		showing: false,
		data: null
	});
	const [supportingDocsState, setSupportingDocsState] = useState({
		pension: "",
		nhis: "",
		nhf: "",
		lifeAssurance: "",
		reciept: ""
	});
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	let { state } = useLocation();
	const history = useHistory();

	function getBase64(file, cb = () => { }) {
		if (file) {
			var filereader = new FileReader();
			filereader.readAsDataURL(file);
			filereader.onload = function (evt) {
				var base64 = evt.target.result;
				cb(base64);
				return base64;
			};
		}
	}

	const base64Factory = (doc, isObj) => {
		if (isObj) {
			Object?.entries(doc)?.forEach(([key, value]) => {
				if (value) {
					getBase64(value, (base64File) =>
						setSupportingDocsState((prev) => ({
							...prev,
							[key]: base64File
						}))
					);
				}
			});
		} else {
			getBase64(doc, (base64File) =>
				setSupportingDocsState((prev) => ({
					...prev,
					signature: base64File
				}))
			);
		}
	};

	useEffect(() => {
		if (filingData?.supportingDocs && filingData?.authority) {
			base64Factory(filingData?.supportingDocs, true);
			base64Factory(filingData?.authority?.signature, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filingData?.supportingDocs, filingData?.authority]);

	const filingDate =
		filingData?.authority?.dateFiled ?? new Date().getFullYear();
	const filingYear = String(Number(new Date().getFullYear() - 1));

	const allBillNumbers = filingData?.billNumberData
		? filingData?.billNumberData?.data?.map(
			({ BillNumber, Total_Grand_Bill_Amount, createdAt }) => ({
				receiptNumber: "",
				billNumber: BillNumber,
				issuedTo: "",
				amount: Total_Grand_Bill_Amount,
				paymentDate: new Date(
					createdAt
				).toLocaleDateString(),
				revenueSource: ""
			})
		)
		: [];

	const allRecieptData = filingData?.recieptData
		? filingData?.recieptData?.data?.map(
			({ ReceiptNo, Amount, createdAt }) => ({
				receiptNumber: ReceiptNo,
				billNumber: "",
				issuedTo: "",
				amount: Amount,
				paymentDate: new Date(
					createdAt
				).toLocaleDateString(),
				revenueSource: ""
			})
		)
		: []

	const submitFiling = async (e) => {
		setLoading(true);
		const payload = {
			companyId:
				filingData?.annualReturnInfo?.corporateCompanyInfoId ?? "",
			annualReturnId: filingData?.annualReturnInfo?.id ?? "",
			filingType: filingData?.typeOfFiling ?? " ",
			filingMode: filingData?.filingMode ?? " ",
			filingTypeMth: filingData?.monthOfReturns ?? " ",
			filingPeriodStartDate: !filingData?.startMonthOfReturns
			? null : filingData?.startMonthOfReturns,
			filingPeriodEndDate: !filingData?.endMonthOfReturns
			? null : filingData?.endMonthOfReturns,
			filingYear: filingData?.yearOfReturns ?? filingYear,
			signature: {
				name: filingData?.authority?.signatory ?? "",
				phoneno: filingData?.authority?.phone ?? "",
				email: filingData?.authority?.email ?? "",
				designation: filingData?.authority?.designation ?? "",
				dateFiled: filingData?.yearOfReturns ?? filingDate,
				signature: supportingDocsState?.signature ?? ""
			},
			billNumbers: [
				...allBillNumbers,
				...allRecieptData
			],
			docs: [
				{
					description: "PENSION",
					doc: supportingDocsState?.pension ?? ""
				},
				{
					description: "NHF",
					doc: supportingDocsState?.nhf ?? ""
				},
				{
					description: "NHIS",
					doc: supportingDocsState?.nhis ?? ""
				},
				{
					description: "LIFE ASSURANCE",
					doc: supportingDocsState?.lifeAssurance ?? ""
				},
				{
					description: "RECEIPT",
					doc: supportingDocsState?.reciept ?? ""
				}
			]
		};

		try {
			const accessToken = localStorage.getItem("access_token");
			let data = await axios
				.post(`corporate/company/annual-returns`, payload, {
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				})
				.then((res) => res.data || { data: {} });

			if (!data || !data?.data) {
				// handle errors
				return null;
			}

			if (data || data?.data) {
				setAlert({
					showing: true,
					type: "success",
					message:
						data?.message ??
						"Company Annual Return Added Successfully"
				});
				setShowModal(true);
			}
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message: error.response.data?.msg ||
					error.response.data?.errors?.[0] ||
					error.response.data?.errors?.[0]?.message ||
					error.response.data?.errors?.details[0]?.message ||
					error.response.message ||
					error.response.data.message ||
					"Company Annual Return Added Unsuccessfully!"
			});
		} finally {
			setLoading(false);
		}
	};

	const getAnssid = async () => {
		try {
			const accessToken = localStorage.getItem("access_token");
			let data = await axios
				.get(
					`users/anssid?anssid=${!userInfo?.anssid ? state?.esbn : userInfo?.anssid
					}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data || { data: {} });

			if (!data || !data?.data) {
				// handle errors
				return null;
			}
			setAnssid(data?.data);
		} catch (error) { }
	};

	const fetchH1 = async () => {
		setLoadingH1Data(true);
		try {
			const accessToken = localStorage.getItem("access_token");
			let data = await axios
				.get(
					`corporate/company/annual-returns/${filingData?.annualReturnInfo?.id}/${filingData?.annualReturnInfo?.corporateCompanyInfoId}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data || { data: {} });

			if (!data || !data?.data) {
				// handle errors
				return null;
			}
			if (data) {
				setDownloadH1ModalStatus((prev) => ({
					...prev,
					showing: true,
					data: {
						...data,
						filingData
					}
				}));
				setShowModal(false);
			}
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response?.data?.message ??
					"H1 Data Fetching Unsuccessful!"
			});
		} finally {
			setLoadingH1Data(false);
		}
	};

	const printH1File = () => {
		if (H1File) {
			const w = window.open();
			if (w.document) {
				w.document.write(H1File);
				w.document.close();
				w.onload = () => {
					w.focus();
					w.print();
				};

				w.onafterprint = () => {
					w.close();
				};
			} else {
				return;
			}
		} else {
			return;
		}
	};

	const verifyBillNo = async () => {
		setVerifyingBillNo(true);
		try {
			const verifiedBill = await axios.get(`gettapayenow/${downloadH1ModalStatus?.data?.data?.annualReturnInfo?.id}/1`);
			if (verifiedBill) {
				setBillNo(verifiedBill?.data?.billno);
				setAlert({
					showing: true,
					type: "success",
					message: 'Bill Found!'
				});
				setVerifyingBillNo(false);
			}
		} catch (error) {
			const message = error.response.data?.msg ||
				error.response.data?.errors?.[0] ||
				error.response.data?.errors?.[0]?.message ||
				error.response.data?.errors?.details[0]?.message ||
				error.response.message ||
				error.response.data.message;
			if (message === "Bill number cannot be identified!") {
				generateBillNo()
			} else {
				setVerifyingBillNo(false);
			}
		}
	}
	const generateBillNo = async () => {
		setVerifyingBillNo(true);

		const payload = {
			coyname: anssid?.name,
			esbn: anssid?.anssid,
			paytype: filingData?.typeOfFiling, // 'Yearly', 'Monthly', 'Period'
			mode: filingData?.filingMode, // self-service=0; admin=1
			year: filingData?.yearOfReturns,
			mth: filingData?.monthOfReturns ? filingData?.monthOfReturns : null,
			payperiodstartdate: filingData?.startMonthOfReturns ? filingData?.startMonthOfReturns : null,
			payperiodenddate: filingData?.endMonthOfReturns ? filingData?.endMonthOfReturns : null,
			filingArrearFlg: true,
			approve: "5", // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval
			coystaff: filingData?.employees?.map((employee, index) => ({
				SN: index + 1,
				EMPLOYEE_TIN: employee?.eSBN,
				NAME: employee?.fullName,
				TOTALINCOME: employee?.annualGrossSalary,
				PENSION: employee?.annualPension,
				NHIS: employee?.annualNHIS,
				NHF: employee?.annualNHF,
				LIFE: employee?.annualLifeAssurance
			}))
		}

		try {
			const generatedBill = await axios.post(
				`gottapayebillnow/${downloadH1ModalStatus?.data?.data?.annualReturnInfo?.id}`, payload
			);
			if (generatedBill) {
				setBillNo(generatedBill?.data?.billno);
				setAlert({
					showing: true,
					type: "success",
					message: 'Bill Generated Successfully!'
				});
			}
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message: error.response.data?.msg ||
					error.response.data?.errors?.[0] ||
					error.response.data?.errors?.[0]?.message ||
					error.response.data?.errors?.details[0]?.message ||
					error.response.message ||
					error.response.data.message
			});
		} finally {
			setVerifyingBillNo(false);
		}
	}

	const openPaymentModal = () => {
		setPaymentModal(true);
		setDownloadH1ModalStatus({
			downloading: false,
			showing: false,
			data: null
		});
	}

	useEffect(() => {
		getAnssid();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section>
			{/* <GoBackButton
			action={() => {
				updateFilingData(filingData);
				updateTab(1);
			}}
			/> */}
			{paymentModal &&
				<PaymentModal
					showing={paymentModal}
					billNumber={billNo}
					goBackTo={
						filingData?.filingMode === '0'
							? '/WHT-filing-corporate'
							: '/wht-filing/admin-filing-table'
					}
					toggle={(val) => setPaymentModal(val)}
				/>
			}
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{showModal && (
				<RegularModal showing={showModal}>
					<p style={{ textAlign: "center", width: "420px" }}>
						You have at this point, successfully submitted your annual filing which will be pending till it is approved or denied by the Assessment HOD and would be sent via email.
					</p>

					<div
						style={{ justifyContent: "center" }}
						className={styles.action__btns}
					>
						<button
							className={`btn btn-success`}
							style={{ padding: "10px 50px" }}
							onClick={() => fetchH1()}
						>
							<p>
								{loadingH1Data
									? "Continuing..."
									: "Continue"}
							</p>
						</button>

						{/* <button
							className={`btn btn-success ${styles.download__btn}`}
							onClick={() => {
								setShowModal(false);
								history.push(
									filingData?.filingMode === '0'
										? '/annual-returns/search'
										: '/annual-returns/admin-filing-table'
								);
							}}
						>
							<p>Continue</p>
						</button> */}
					</div>
				</RegularModal>
			)}
			{downloadH1ModalStatus.showing && downloadH1ModalStatus.data && (
				<RegularModal showing={downloadH1ModalStatus.showing}>
					{variant > 0 &&
						<>
							<p style={{
								width: "411px",
								fontSize: "16px",
								color: "red",
								margin: "30px auto 20px auto",
								textAlign: "center"
							}}>
								Your Annual Filing has variance of ₦{variant}, <br />
								Pay Now to avoid declining of your Filing
							</p>
							<button
								className={`btn btn-success ${styles.download__btn}`}
								style={{
									padding: "10px 50px",
									margin: "0px auto 30px auto"
								}}
								onClick={() => billNo ?
									openPaymentModal()
									: verifyBillNo()}
							>
								<p>
									{
										billNo ?
											'Pay Now' :
											verifyingBillNo ? 'Generating Bill...' : 'Generate Bill and Pay Now'
									}
								</p>
							</button>
						</>
					}
					<H1Document
						data={downloadH1ModalStatus.data}
						getFile={(file) => setH1File(file)}
						getVariant={(variant) => setVariant(variant)}
					/>
					<div className={styles.action__btns}>
						<button
							className={`btn btn-danger`}
							onClick={() => {
								setDownloadH1ModalStatus((h1Modal) => ({
									...h1Modal,
									showing: false,
									data: {}
								}))
								history.push(
									filingData?.filingMode === '0'
										? '/WHT-filing-corporate'
										: '/wht-filing/admin-filing-table'
								);
							}}
						>
							<p>Close Modal</p>
						</button>
						<button
							className={`btn btn-success ${styles.download__btn}`}
							onClick={() => printH1File()}
						>
							<p>Download H1 Details</p>
							<img src={downloadIcon} alt="download" />
						</button>
					</div>
				</RegularModal>
			)}
			<div className={styles.view__filing__wrapper}>
				<div className={styles.view__filing__header}>
					<p className={styles.header__title}>Step 5: Preview</p>
				</div>
				<hr />

				<div>
					<div className={`bg-warning ${styles.complaint}`}>
						<p>
							<strong>
								Based on your Employee Emoluments upload and
								allowances, find below your H1. Kindly Verify
								and File
							</strong>
						</p>
					</div>
				</div>

				<div className={`row ${styles.h1__deets}`}>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Company Name{" "}
								<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>{anssid?.name ?? "N/A"}</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								ESBN<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>{anssid?.anssid ?? "N/A"}</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Address<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>{anssid?.stateOfficeAddress ?? "N/A"}</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Contact Email Address{" "}
								<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>{anssid?.email ?? "N/A"}</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Contact Phone
								<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>
							{anssid?.phoneNumber ??
								anssid?.phonenumber ??
								"N/A"}
						</p>
					</div>
					<div className={`col-md-4`}>
						<p>
							<strong>
								Filing Year
								<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>{filingData?.yearOfReturns ?? filingYear}</p>
					</div>

					<div className={`col-md-4`}>
						<p>
							<strong>
								Type of filing
								<span style={{ color: "red" }}>{" "}*</span>
							</strong>
						</p>
						<p>{filingData?.typeOfFiling ? `By ${filingData?.typeOfFiling}` : 'N/A'}</p>
					</div>
					{filingData?.monthOfReturns && <>
						<div className={`col-md-4`}>
							<p>
								<strong>
									Month of Returns
									<span style={{ color: "red" }}>{" "}*</span>
								</strong>
							</p>
							<p>{filingData?.monthOfReturns || 'N/A'}</p>
						</div>
					</>}
					{filingData?.startMonthOfReturns && <>
						<div className={`col-md-4`}>
							<p>
								<strong>
									Start Month of Returns
									<span style={{ color: "red" }}>{" "}*</span>
								</strong>
							</p>
							<p>{filingData?.startMonthOfReturns || 'N/A'}</p>
						</div>
					</>}
					{filingData?.endMonthOfReturns && <>
						<div className={`col-md-4`}>
							<p>
								<strong>
									End Month of Returns
									<span style={{ color: "red" }}>{" "}*</span>
								</strong>
							</p>
							<p>{filingData?.endMonthOfReturns || 'N/A'}</p>
						</div>
					</>}

					<div className={`col-md-4`}>
						<p>
							<strong>
								Sector<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						<p>{anssid?.economicSector ?? "N/A"}</p>
					</div>
				</div>
				<hr />
				<br />
				<h5 className={styles.entries__title}>
					Employee entries preview
				</h5>
				<br />

				<div className="table-responsive">
					<Table
						headers={headers}
						data={filingData?.employees ?? []}
						full
						pageSize={20}
						total={filingData?.employees?.length}
					/>
				</div>
				<br />

				<div className={`row ${styles.entries__deets}`}>
					<div className={`col-md-3`}>
						{filingData?.authority?.signature && (
							<img
								className={styles.entries__title__signature}
								src={
									filingData?.authority?.signature
										? URL?.createObjectURL(
											filingData?.authority?.signature
										)
										: signature
								}
								alt="Signature"
							/>
						)}
						<p>Signature</p>
					</div>
					<div className={`col-md-3`}>
						<p>
							<strong>
								{filingData?.authority?.signatory ?? "N/A"}
							</strong>
						</p>
						<p>Name</p>
					</div>
					<div className={`col-md-3`}>
						<p>
							<strong>
								{filingData?.authority?.designation ?? "N/A"}
							</strong>
						</p>
						<p>Designation</p>
					</div>

					{/* not available in APIs for now */}
					<div className={`col-md-3`}>
						<p>
							<strong>{filingDate ?? "N/A"}</strong>
						</p>
						<p>Date Filed</p>
					</div>
				</div>
				<br />
				<br />
				<br />
				<hr />
				<br />
				<h5 className={styles.entries__title}>
					Reciept Number for each remittance made in {filingData?.yearOfReturns ?? filingYear}
				</h5>
				<br />

				<Table
					headers={recieptTableHeaders}
					data={filingData?.recieptData?.data ?? []}
					full
					pageSize={20}
					total={filingData?.recieptData?.data.length}
				/>
				<hr />
				<br />

				<h5 className={styles.entries__title}>
					SUPPORTING DOCUMENTS ATTACHED
				</h5>

				<div className={`bg-warning ${styles.py__2}`}>
					<small>
						Click on the file name to view or download document
					</small>
				</div>
				<br />

				<div className={`row ${styles.variants__deets}`}>
					<div className={`col-md-6`}>
						<p>
							<strong>
								Payment Receipt: Annual Pension (Max: 1mb){" "}
								<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						{filingData?.supportingDocs?.pension && (
							<>
								<FileIcon />{" "}
								<a
									href={URL?.createObjectURL(
										filingData?.supportingDocs?.pension
									)}
								>
									{filingData?.supportingDocs?.pension?.name}
								</a>
							</>
						)}
					</div>
					<div className={`col-md-6`}>
						<p>
							<strong>
								Payment Receipt: Annual NHF (Max: 1mb){" "}
								<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						{filingData?.supportingDocs?.nhf && (
							<>
								<FileIcon />{" "}
								<a
									href={URL?.createObjectURL(
										filingData?.supportingDocs?.nhf
									)}
								>
									{filingData?.supportingDocs?.nhf?.name}
								</a>
							</>
						)}
					</div>
				</div>

				<br />
				<div className={`row ${styles.variants__deets}`}>
					<div className={`col-md-6`}>
						<p>
							<strong>
								Payment Receipt: Annual NHIS (Max: 1mb){" "}
								<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						{filingData?.supportingDocs?.nhis && (
							<>
								<FileIcon />{" "}
								<a
									href={URL?.createObjectURL(
										filingData?.supportingDocs?.nhis
									)}
								>
									{filingData?.supportingDocs?.nhis?.name}
								</a>
							</>
						)}
					</div>

					<div className={`col-md-6`}>
						<p>
							<strong>
								Payment Receipt: Life assurance (Max: 1mb){" "}
								<span style={{ color: "red" }}>*</span>
							</strong>
						</p>
						{filingData?.supportingDocs?.lifeAssurance && (
							<>
								<FileIcon />{" "}
								<a
									href={URL?.createObjectURL(
										filingData?.supportingDocs
											?.lifeAssurance
									)}
								>
									{
										filingData?.supportingDocs
											?.lifeAssurance?.name
									}
								</a>
							</>
						)}
					</div>
				</div>
				<br />
				<div className={`${style.agreement} ${style.ml_1}`}>
					<input
						type="checkbox"
						id="agreement"
						onChange={(e) => setAgreement(e.target.checked)}
					/>
					<label htmlFor="agreement">
						I hereby certify that the above is a complete and
						accurate list of staff of the company that are tax
						resident in Enugu State. I also certify that the
						information on their salaries and allowances are
						accurate and exhaustive. I make this certification
						knowing it to be true in accordance with the provisions
						of the Personal Income Tax Act(Amended) 2011 and
						regulations of the personal Income Tax Act 2004 (as
						amended).
					</label>
				</div>
				<br />
				<br />
				<br />
				<footer
					style={{
						marginLeft: 'auto',
						gap: '15px'
					}}
					className={styles.form__footer}
				>
					<button
						disabled={
							!agreement || loading || alert.type === "success"
						}
						type="submit"
						className={[
							"primary__btn",
							style1.primary__button,
							loading ? style1.loading__button : ""
						].join(" ")}
						onClick={() => submitFiling()}
					>
						Submit
					</button>
				</footer>
				<br />
				<br />
			</div>
		</section>
	);
};
