/* eslint-disable eqeqeq */
import Pagination from "./Pagination";
import styles from "./style.module.css";

const Table = ({
	headers,
	data = [],
	full,
	total = data.length,
	pageSize = 20,
	onPageChanged,
	onEdit,
	onView,
	onApprove,
	onOthers,
	showingTotal = true
}) => {
	// const pages = Array(Math.ceil(total / data.length)).fill(1);
	return (
		<>
			<div className={styles.table__wrapper}>
				<table className={styles.table}>
					<thead>
						{/* <th>Serial No.</th> */}
						<tr className={styles.table__row}>
							{headers.map((header, i) => {
								if (full) {
									return <th key={i}>{header[0]}</th>;
								} else
									return i > 4 ? null : (
										<th key={i}>{header[0]}</th>
									);
							})}
						</tr>
					</thead>
					<tbody>
						{data.map((each, i) => (
							<tr className={styles.table__row} key={i}>
								{/* <td>{i + 1}</td> Serial number column */}
								{headers.map((header, i) => {
									const value = header[2]
										? header[2](each[header[1]])
										: each[header[1]];
									if (full) {
										return (
											<td key={i}>
												{value != undefined
													? value
													: "N/A"}
											</td>
										);
									} else
										return i > 4 ? null : (
											<td key={i}>
												{value != undefined
													? value
													: "N/A"}
											</td>
										);
								})}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{full && (
				<footer className={styles.table__footer}>
					{showingTotal && (
						<p>
							Displaying {data.length} out of {total}
						</p>
					)}
					{total && (
						<Pagination
							totalRecords={total}
							pageLimit={pageSize}
							pageNeighbours={1}
							onPageChanged={onPageChanged}
						/>
					)}
				</footer>
			)}
		</>
	);
};

export default Table;
