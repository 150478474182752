import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import Alert from "../../../components/Alert";
import GoBackButton from "../../../components/goBackButton";
import ApproveRejectWidget from "../../../components/ApproveRejectWidget";
import styles from "../Change/style.module.css";
import formStyles from "../../../Identity-management-individual/style.module.css";

const EmailAndPhoneChangeAuthView = () => {
	const { state: locationState } = useLocation();
	const history = useHistory();

	const [ESBN, setESBN] = useState(locationState?.esbn || "");
	const [name, setName] = useState("");
	const [address, setAddress] = useState("");
	const [currentEmail, setCurrentEmail] = useState(
		locationState?.oldemail || ""
	);
	const [currentPhone, setCurrentPhone] = useState(
		locationState?.oldfone || ""
	);
	const [newEmail, setNewEmail] = useState(locationState?.newemail || "");
	const [newPhone, setNewPhone] = useState(locationState?.newfone || "");
	const [fetching, setFetching] = useState(false);
	const [rejectReason, setRejectReason] = useState("");
	const [rejecting, setRejecting] = useState(false);
	const [approving, setApproving] = useState(false);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const getESBNDetails = async () => {
		setFetching(true);

		try {
			const res = await axios
				.get(`/users/anssid?anssid=${ESBN}`)
				.then((res) => (res.data || {}).data);

			if (res.accounttype === "individual") {
				setName(`${res.firstname} ${res.middlename} ${res.surname}`);
				setAddress(res.residentialaddress || "");
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: "ESBN provided is not an individual's ESBN"
				});
			}
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else if (error.request) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setFetching(false);
		}
	};

	const reject = async (e) => {
		e.preventDefault();

		try {
			setRejecting(true);

			const req = await axios
				.put("/globalprocessforchange", {
					globId: "10",
					approve: "2",
					reason4Reject: rejectReason,
					recId: locationState?.id,
					esbn: ESBN,
					oldname: name,
					oldaddr: address,
					oldemail: currentEmail,
					oldfone: currentPhone,
					newemail: newEmail,
					newfone: newPhone
				})
				.then((res) => res.data || {});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: req.msg
			});

			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.error ||
						error.response.data?.message ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRejecting(false);
		}
	};

	const revoke = async (e) => {
		e.preventDefault();

		try {
			setRejecting(true);

			const req = await axios
				.put("/globalprocessforchange", {
					globId: "10",
					approve: "4",
					reason4Reject: rejectReason,
					recId: locationState?.id,
					esbn: ESBN,
					oldname: name,
					oldaddr: address,
					oldemail: currentEmail,
					oldfone: currentPhone,
					newemail: newEmail,
					newfone: newPhone
				})
				.then((res) => res.data || {});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: req.msg
			});

			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.message ||
						error.response.data?.error ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRejecting(false);
		}
	};

	const approve = async () => {
		try {
			setApproving(true);

			const req = await axios
				.put("/globalprocessforchange", {
					globId: "10",
					approve: "3",
					reason4Reject: rejectReason,
					recId: locationState?.id,
					esbn: ESBN,
					oldname: name,
					oldaddr: address,
					oldemail: currentEmail,
					oldfone: currentPhone,
					newemail: newEmail,
					newfone: newPhone
				})
				.then((res) => res.data || {});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: req.msg
			});

			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setApproving(false);
		}
	};

	useEffect(() => {
		if (ESBN?.length >= 10) getESBNDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ESBN]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<GoBackButton />
			<form className={formStyles.columned__form}>
				<div className={formStyles.double__inputs}>
					<div>
						<label htmlFor="ESBN">
							ESBN <span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="number"
							id="ESBN"
							value={ESBN}
							onChange={(e) => setESBN(e.target.value)}
							disabled
						/>
						{fetching && (
							<p
								style={{
									fontSize: "12px",
									marginTop: "-25px",
									color: "#9e9e9e"
								}}
							>
								Fetching details...
							</p>
						)}
					</div>
				</div>
				<hr />
				<div className={formStyles.double__inputs}>
					<div>
						<label htmlFor="name">Name</label>
						<input
							type="text"
							id="name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							disabled
						/>
					</div>
					<div>
						<label htmlFor="address">Address</label>
						<input
							type="text"
							id="address"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							disabled
						/>
					</div>
					<div>
						<label htmlFor="currentEmail">Current Email</label>
						<input
							type="email"
							id="currentEmail"
							value={currentEmail}
							onChange={(e) => setCurrentEmail(e.target.value)}
							disabled
						/>
					</div>
					<div>
						<label htmlFor="currentPhone">
							Current Phone Number
						</label>
						<input
							type="tel"
							id="currentPhone"
							value={currentPhone}
							onChange={(e) => setCurrentPhone(e.target.value)}
							disabled
						/>
					</div>
				</div>
				<hr />
				<h2 className={styles.h2}>New Details</h2>
				<div className={formStyles.double__inputs}>
					<div>
						<label htmlFor="newEmail">New Email</label>
						<input
							type="email"
							id="newEmail"
							value={newEmail}
							onChange={(e) => setNewEmail(e.target.value)}
							disabled
						/>
					</div>
					<div>
						<label htmlFor="newPhone">New Phone Number</label>
						<input
							type="tel"
							id="newPhone"
							value={newPhone}
							onChange={(e) => setNewPhone(e.target.value)}
							disabled
						/>
					</div>
				</div>
			</form>
			{(locationState.approveflg === "5" ||
				locationState.approveflg === "3") && (
				<ApproveRejectWidget
					type={+locationState.approveflg}
					positiveIdiotProofQuestion={`for "${locationState?.esbn}" email/phone change request`}
					processingPositive={approving}
					positiveFunction={approve}
					processingNegative={rejecting}
					negativeIdiotProofQuestion={`"${locationState?.esbn}" email/phone change request`}
					negativeReasonMessage={`You will be ${
						locationState.approveflg === "5"
							? "rejecting"
							: "revoking"
					} “${
						locationState?.esbn
					}” email/phone change request, an email would be sent to notify them accordingly.`}
					negativeReason={rejectReason}
					setNegativeReason={setRejectReason}
					negativeFunction={
						locationState.approveflg === "5" ? reject : revoke
					}
				/>
			)}
		</section>
	);
};

export default EmailAndPhoneChangeAuthView;
