import React, { createRef, useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from "react-router";
import Table from '../../../components/Table';
import Alert from '../../../components/Alert';
import { H1Document } from '../modals/h1Document';
import style from "../style.module.css";
import RegularModal from '../../../components/Modal/regular';
import styles1 from "../../../Dashboard/MDAs/style.module.css";
import downloadIcon from "../../../assets/downloadFilingIcon.svg";

function getStatusText(approveRejectFlag) {
    // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval; 6: Deleted
    if (approveRejectFlag === "0") {
        return "Pending";
    } else if (approveRejectFlag === "1") {
        return "Cancelled";
    } else if (approveRejectFlag === "2") {
        return "Declined";
    } else if (approveRejectFlag === "3") {
        return "Approved";
    } else if (approveRejectFlag === "4") {
        return "Revoked";
    } else if (approveRejectFlag === "5") {
        return "Pending Approval";
    } else {
        return "Deleted";
    }
}

const headers = [
    ["Organization", "StateTinName"],
    ["ESBN", "StateTin"],
    ["Filed Type", "FilingType"],
    ["Filed Month", "FilingTypeMonth"],
    ["Filing Start Date", "FilingPeriodStartDate"],
    ["Filing End Date", "FilingPeriodEndDate"],
    ["Filed Year", "FilingYear"],
    ["Filed Status", "FilingStatus"],
    ["Arrears Bill Number", "ArrearsBillNumber"],
    ["Payment Status", "PaymentStatus"],
    ["Process Status", "ApproveFlg", getStatusText],
    ["Reason for rejection", "RejectReason"],
    ["Inputter", "createdBy"]
];

export const EFilingReviewer = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [H1File, setH1File] = useState([]);
    const [total, setTotal] = useState(0);
    const [filters, setFilters] = useState({
        ESBN: "",
        agentName: "",
        Inputter: "",
        filedYear: "",
        startDate: "",
        endDate: ""
    });
    const [downloadH1ModalStatus, setDownloadH1ModalStatus] = useState({
        downloading: false,
        showing: false,
        data: null
    });
    const [alert, setAlert] = useState({
        showing: false,
        type: null,
        message: ""
    });
    const filterRef = createRef();
    const history = useHistory();

    const fetchH1 =
        ({ currentPage = 1 } = {}) =>
            async (e) => {
                e?.preventDefault?.();

                if (!filterRef?.current?.classList?.contains(styles1.no__display)) {
                    filterRef?.current?.classList?.toggle(styles1.no__display);
                }

                let query = `page=${currentPage}&pageSize=20&`;
                if (filters?.filedYear) {
                    query += `filingYear=${filters?.filedYear}&`;
                }

                if (filters?.agentName) {
                    query += `stateTinName=${filters?.agentName}&`;
                }

                if (filters?.ESBN) {
                    query += `stateTin=${filters?.ESBN}&`;
                }

                if (filters?.Inputter) {
                    query += `Inputter=${filters?.Inputter}&`;
                }

                if (filters?.startDate) {
                    query += `startdate=${filters?.startDate}&`;
                }

                if (filters?.endDate) {
                    query += `enddate=${filters?.endDate}&`;
                }

                try {
                    const accessToken = localStorage.getItem("access_token");
                    let data = await axios
                        .get(`adminlandpage/annual-returns/reviewer?${query}`, {
                            headers: {
                                Authorization: `Bearer ${accessToken}`
                            }
                        })
                        .then((res) => res?.data || {});

                    if (!data || !data?.data) {
                        setAlert({
                            showing: true,
                            type: "error",
                            message:
                                data?.message ??
                                "Fetching Unsuccessful!"
                        });
                    }

                    setLoading(false);
                    setData(data?.data);
                    setTotal(data?.total);
                } catch (error) {
                    setAlert({
                        showing: true,
                        type: "error",
                        message:
                            error?.response?.data?.message ??
                            "Fetching Unsuccessful!"
                    });
                    setLoading(false);
                }
            };

    const getH1 = async (annualID, corporateCompanyID) => {
        try {
            const accessToken = localStorage.getItem("access_token");
            let data = await axios
                .get(
                    `corporate/company/annual-returns/${annualID}/${corporateCompanyID}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    }
                )
                .then((res) => res.data || { data: {} });

            if (!data || !data?.data) {
                return null;
            }
            if (data) {
                setDownloadH1ModalStatus((h1Modal) => ({
                    ...h1Modal,
                    showing: true,
                    data: data
                }));
            }
        } catch (error) {
            setAlert({
                showing: true,
                type: "error",
                message:
                    error?.response?.data?.message ??
                    "H1 Data Fetching Unsuccessful!"
            });
        }
    };

    const printH1File = () => {
        if (H1File) {
            const w = window.open();
            if (w.document) {
                w.document.write(H1File);
                w.document.close();
                w.onload = () => {
                    w.focus();
                    w.print();
                };

                w.onafterprint = () => {
                    w.close();
                };
            } else {
                return;
            }
        } else {
            return;
        }
    };

    useEffect(() => {
        fetchH1()();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const delay = setTimeout(
            () =>
                setAlert({
                    showing: false,
                    type: null,
                    message: ""
                }),
            3000
        );

        return () => clearTimeout(delay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!alert.showing]);
    return (
        <div className={styles1.MDA__page}>
            {alert.showing && <Alert text={alert.message} type={alert.type} />}
            {downloadH1ModalStatus.showing && (
                <RegularModal showing={downloadH1ModalStatus.showing}>
                    <H1Document
                        data={downloadH1ModalStatus?.data}
                        getFile={(file) => setH1File(file)}
                    />
                    <div className={style.action__btns}>
                        <button
                            className={`btn btn-danger`}
                            onClick={() =>
                                setDownloadH1ModalStatus((h1Modal) => ({
                                    ...h1Modal,
                                    showing: false,
                                    data: {}
                                }))
                            }
                        >
                            <p>Close Modal</p>
                        </button>
                        <button
                            className={`btn btn-success ${style.download__btn}`}
                            onClick={() => printH1File()}
                        >
                            <p>Download H1 Details</p>
                            <img src={downloadIcon} alt="download" />
                        </button>
                    </div>
                </RegularModal>
            )}
            <div
                className={styles1.actions__div}
                style={{ display: "flex", justifyContent: "flex-end" }}
            >
                <div className={styles1.filter}>
                    <button
                        onClick={() => {
                            filterRef.current.classList.toggle(
                                styles1.no__display
                            );
                        }}
                        className={styles1.filter__button}
                    >
                        Filter
                    </button>
                    <div
                        ref={filterRef}
                        className={[
                            styles1.filter__div,
                            styles1.no__display
                        ].join(" ")}
                    >
                        <div className={styles1.filter__header}>
                            <h6>Filter</h6>
                            <button
                                aria-labelledby="close filter button"
                                onClick={() => {
                                    filterRef.current.classList.toggle(
                                        styles1.no__display
                                    );
                                }}
                            >
                                X
                            </button>
                        </div>
                        <button
                            className={styles1.clear__filter_fields}
                            disabled={true}
                        >
                            Clear Filter fields
                        </button>
                        <p>By Code</p>
                        <form>
                            <div>
                                <label htmlFor="owneresbn">Esbn</label>
                                <input
                                    name="owneresbn"
                                    id="owneresbn"
                                    type="text"
                                    value={filters.ESBN}
                                    onChange={(e) =>
                                        setFilters(() => ({
                                            ...filters,
                                            ESBN: e.target.value
                                        }))
                                    }
                                    placeholder="Esbn"
                                />
                            </div>
                        </form>
                        <p>By Filing Details</p>
                        <form>
                            <div>
                                <label htmlFor="filedYear">Filed Year</label>
                                <input
                                    name="filedYear"
                                    id="filedYear"
                                    type="date"
                                    value={filters.filedYear}
                                    onChange={(e) =>
                                        setFilters(() => ({
                                            ...filters,
                                            filedYear: e.target.value
                                        }))
                                    }
                                    placeholder="Eg 22/12/1990"
                                />
                            </div>
                            <div>
                                <label htmlFor="agentName">Organization</label>
                                <input
                                    name="agentName"
                                    id="agentName"
                                    type="text"
                                    placeholder='Name of Organization'
                                    value={filters.agentName}
                                    onChange={(e) =>
                                        setFilters(() => ({
                                            ...filters,
                                            agentName: e.target.value
                                        }))
                                    }
                                />
                            </div>
                            <div>
                                <label htmlFor="Inputter">Inputter</label>
                                <input
                                    name="Inputter"
                                    id="Inputter"
                                    type="text"
                                    placeholder='Name of inputter'
                                    value={filters.Inputter}
                                    onChange={(e) =>
                                        setFilters(() => ({
                                            ...filters,
                                            Inputter: e.target.value
                                        }))
                                    }
                                />
                            </div>
                        </form>
                        <p>By Creation Date</p>
                        <form>
                            <div>
                                <label htmlFor="start_date">Start Date</label>
                                <input
                                    name="start_date"
                                    id="start_date"
                                    type="date"
                                    value={filters?.startDate}
                                    onChange={(e) =>
                                        setFilters(() => ({
                                            ...filters,
                                            startDate: e.target.value
                                        }))
                                    }
                                />
                            </div>
                            <div>
                                <label htmlFor="start_date">End Date</label>
                                <input
                                    name="end_date"
                                    id="end_date"
                                    type="date"
                                    value={filters?.endDate}
                                    onChange={(e) =>
                                        setFilters(() => ({
                                            ...filters,
                                            endDate: e.target.value
                                        }))
                                    }
                                />
                            </div>
                        </form>
                        <button
                            className={[
                                styles1.submit__button,
                                "primary__btn"
                            ].join(" ")}
                            onClick={fetchH1()}
                        >
                            Search
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles1.table__div}>
                {loading ? (
                    <p style={{ textAlign: "center" }}>Loading...</p>
                ) : (
                    <Table
                        headers={headers}
                        data={data}
                        full
                        pageSize={20}
                        total={total}
                        onPageChanged={fetchH1}
                        onView={(data) => {
                            history.push(`/paye-filing/corporate-view`, {
                                coy: data?.id,
                                corporateCompanyID: data.CorporateCompanyInfoId,
                                viewer: 'reviewer'
                            });
                        }}
                        onH1={(data) => {
                            getH1(data?.id, data?.CorporateCompanyInfoId);
                        }}
                    />
                )}
            </div>
        </div>
    )
}
