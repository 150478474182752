import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import Alert from "../../../components/Alert";
import styles from "./style.module.css";
import formStyles from "../../../Identity-management-individual/style.module.css";

const ChangeEmailAndPhone = () => {
	const history = useHistory();
	const [ESBN, setESBN] = useState("");
	const [name, setName] = useState("");
	const [address, setAddress] = useState("");
	const [currentEmail, setCurrentEmail] = useState("");
	const [currentPhone, setCurrentPhone] = useState("");
	const [emailChecked, setEmailChecked] = useState(false);
	const [phoneChecked, setPhoneChecked] = useState(false);
	const [newEmail, setNewEmail] = useState("");
	const [isNewEmailInputVisible, setNewEmailInputVisibility] = useState(true);
	const [isNewPhoneInputVisible, setNewPhoneInputVisibility] = useState(true);
	const [newPhone, setNewPhone] = useState("");
	const [fetching, setFetching] = useState(false);
	const [saving, setSaving] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const getESBNDetails = async () => {
		setFetching(true);

		try {
			const res = await axios
				.get(`/users/anssid?anssid=${ESBN}`)
				.then((res) => (res.data || {}).data);

			if (res.accounttype === "individual") {
				setName(`${res.firstname} ${res.middlename} ${res.surname}`);
				setAddress(res.residentialaddress || "");
				setCurrentEmail(res.email);
				setCurrentPhone(res.phonenumber);
			} else {
				setName(res.name);
				setAddress(res.stateOfficeAddress || "");
				setCurrentEmail(res.email);
				setCurrentPhone(res.phoneNumber);
			}
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else if (error.request) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setFetching(false);
		}
	};

	const saveChanges = async (e) => {
		e.preventDefault();
		setSaving(true);

		try {
			const res = await axios
				.post(`/changefoneemail`, {
					esbn: ESBN,
					oldname: name,
					oldaddr: address,
					oldemail: currentEmail,
					oldfone: currentPhone,
					newemail: newEmail ? newEmail : currentEmail,
					newfone: newPhone ? newPhone : currentPhone
				})
				.then((res) => res.data);

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res?.msg || "Changes saved successfully"
			});
			setESBN("");
			setName("");
			setAddress("");
			setCurrentEmail("");
			setCurrentPhone("");
			setEmailChecked(false);
			setPhoneChecked(false);
			setNewEmail("");
			setNewPhone("");

			setTimeout(() => history.push("/email-phone-change/record"), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.message
				});
			} else if (error.request) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setSaving(false);
		}
	};

	useEffect(() => {
		if (ESBN?.length >= 10) getESBNDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ESBN]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<form className={formStyles.columned__form} onSubmit={saveChanges}>
				<div className={formStyles.double__inputs}>
					<div>
						<label htmlFor="ESBN">
							ESBN <span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="number"
							id="ESBN"
							value={ESBN}
							onChange={(e) => {
								setESBN(e.target.value);
								setName("");
								setAddress("");
								setCurrentEmail("");
								setCurrentPhone("");
								setEmailChecked(false);
								setPhoneChecked(false);
								setNewEmail("");
								setNewPhone("");
							}}
							placeholder="enter ESBN"
						/>
						{fetching && (
							<p
								style={{
									fontSize: "12px",
									marginTop: "-25px",
									color: "#9e9e9e"
								}}
							>
								Fetching details...
							</p>
						)}
					</div>
				</div>
				<hr />
				<div className={formStyles.double__inputs}>
					<div>
						<label htmlFor="name">Name</label>
						<input
							type="text"
							id="name"
							value={name}
							onChange={(e) => {
								setName(e.target.value);
							}}
							placeholder="eg. Ada Obi"
							disabled
						/>
					</div>
					<div>
						<label htmlFor="address">Address</label>
						<input
							type="text"
							id="address"
							value={address}
							onChange={(e) => {
								setAddress(e.target.value);
							}}
							placeholder="eg. No. 1 John Nwodo"
							disabled
						/>
					</div>
					<div>
						<label htmlFor="currentEmail">Current Email</label>
						<input
							type="email"
							id="currentEmail"
							value={currentEmail}
							onChange={(e) => {
								setCurrentEmail(e.target.value);
							}}
							placeholder="example@example.com"
							disabled
						/>
					</div>
					<div>
						<label htmlFor="currentPhone">
							Current Phone Number
						</label>
						<input
							type="tel"
							id="currentPhone"
							value={currentPhone}
							onChange={(e) => {
								setCurrentPhone(e.target.value);
							}}
							placeholder="08123456789"
							disabled
						/>
					</div>
				</div>
				<hr />
				<h2 className={styles.h2}>New Details</h2>
				<p className={styles.instruction}>
					Select, at least, one from the following which you would
					like to change. <span style={{ color: "red" }}>*</span>
				</p>
				<div className={styles.checkbox__div}>
					<div>
						<input
							type="checkbox"
							id="emailChecked"
							checked={emailChecked}
							onChange={(e) => {
								setEmailChecked(!emailChecked);
								if (e.target.checked) {
									setNewEmailInputVisibility(true);
									if (!phoneChecked) {
										setNewPhoneInputVisibility(false);
									}
								} else {
									setNewEmailInputVisibility(false);
								}
							}}
						/>
						<label htmlFor="emailChecked">Email</label>
					</div>
					<div>
						<input
							type="checkbox"
							id="phoneChecked"
							checked={phoneChecked}
							onChange={(e) => {
								setPhoneChecked(!phoneChecked);
								if (e.target.checked) {
									setNewPhoneInputVisibility(true);
									if (!emailChecked) {
										setNewEmailInputVisibility(false);
									}
								} else {
									setNewPhoneInputVisibility(false);
								}
							}}
						/>
						<label htmlFor="phoneChecked">Phone Number</label>
					</div>
				</div>
				<div className={formStyles.double__inputs}>
					{isNewEmailInputVisible && (
						<div>
							<label htmlFor="newEmail">New Email</label>
							<input
								type="email"
								id="newEmail"
								value={newEmail}
								onChange={(e) => {
									setNewEmail(e.target.value);
								}}
								placeholder="enter new email"
							/>
						</div>
					)}
					{isNewPhoneInputVisible && (
						<div>
							<label htmlFor="newPhone">New Phone Number</label>
							<input
								type="tel"
								id="newPhone"
								value={newPhone}
								onChange={(e) => {
									setNewPhone(e.target.value);
								}}
								placeholder="enter new phone number"
							/>
						</div>
					)}
				</div>
				<button
					type="submit"
					className={`primary__btn ${styles.submit__btn}`}
					disabled={
						!ESBN ||
						!name ||
						(!emailChecked && !phoneChecked) ||
						(emailChecked && !newEmail) ||
						(phoneChecked && !newPhone)
					}
				>
					{saving ? "Saving..." : "Save Changes"}
				</button>
			</form>
		</section>
	);
};

export default ChangeEmailAndPhone;
