import React, { useState } from "react";
import { StepFive } from "./corporateTabs/stepFive";
import { FilingStep } from "./corporateTabs/filingStep";

export const FileWHTAnnualReturns = () => {
	const [tabState, setTabState] = useState(1);
	const [filingData, setFilingData] = useState(null);

	const tabViews =
		tabState === 5 ? (
			<StepFive
				updateTab={(tabNumber) => setTabState(tabNumber)}
				updateFilingData={(data) => setFilingData(data)}
				filingData={filingData}
			/>
		) : (
			<FilingStep
				updateTab={(tabNumber) => setTabState(tabNumber)}
				updateFilingData={(data) => setFilingData(data)}
				filingData={filingData}
			/>
		);

	return (
		<div>
			<div>{tabViews}</div>
		</div>
	);
};
