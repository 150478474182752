import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Alert from "../../components/Alert";
import style from "./style.module.css";
import styles from "../../Identity-management-individual/style.module.css";

const months = [
	"January", "February", "March", "April", "May", "June",
	"July", "August", "September", "October", "November", "December"
];

export const AdminFileAnnualReturn = () => {
	const [loading, setLoading] = useState(false);
	const [subscription, setSubcription] = useState("Yearly");
	const [formData, setFormData] = useState({
		yearOfReturns: new Date().getFullYear() - 1, // Show previous year by default. Mr Femi advised.
		esbn: "",
		monthOfReturns: "",
		startMonthOfReturns: "",
		endMonthOfReturns: ""
	});
	const history = useHistory();

	const clearState = () => {
		setFormData({
			yearOfReturns: new Date().getFullYear() - 1, // Show previous year by default. Mr Femi advised.
			esbn: "",
			monthOfReturns: "",
			startMonthOfReturns: "",
			endMonthOfReturns: ""
		})
	}

	const years = useMemo(() => {
		const currentYear = new Date().getFullYear();
		const pastFiveYears = Array.from(
			{ length: 5 },
			(_, index) => (Number(currentYear) - 1) - index // Mr Femi advised (and Akunna agreed) to remove 2025
		);
		return pastFiveYears;
	}, []);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const handleProfileInArrayChange = (e) => {
		const value = e.target.value;
		const name = e.target.name;
		setFormData((prevState) => ({ ...prevState, [name]: value }));
	};

	const submit = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			let res = await axios
				.post( //Changed from GET to POST (with Mr Femi), to prevent duplicate filing.
					`/comp/annual-returns`,
					{
						esbn: formData.esbn,
						filetype: subscription,  // 'Yearly', 'Monthly', 'Period'
						mode: "1",            // self-service=0; admin=1
						year: formData?.yearOfReturns ?? '',
						mth: formData?.monthOfReturns ?? '',              // if no month, empty string
						psdate: !formData?.startMonthOfReturns ? null :
							formData?.startMonthOfReturns,         // null if date is empty
						pedate: !formData?.endMonthOfReturns ? null :
							formData?.endMonthOfReturns          // null if date is empty
					}
				)
				.then((res) => res.data || {});

			setAlert({
				showing: true,
				type: "success",
				message: res.message
			});

			setTimeout(() => {
				history.push(`/paye-filing/file-returns`, {
					coyID: res?.data?.corporatecoyfoldid,
					esbn: formData.esbn,
					yearOfReturns: formData.yearOfReturns,
					monthOfReturns: formData.monthOfReturns,
					startMonthOfReturns: formData.startMonthOfReturns,
					endMonthOfReturns: formData.endMonthOfReturns,
					typeOfFiling: subscription,
					filingMode: "1"
				});
			}, 3000);
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error.response.data?.msg ||
					error.response.data?.errors?.[0]?.message ||
					error.response.data?.errors?.details[0]?.message ||
					error.response?.data?.message
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);
	return (
		<form className={styles.form} onSubmit={submit}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<header>
				<h2>File Returns</h2>
				<hr />
			</header>
			<p>
				{
					subscription === "Yearly" ? "Annual" :
						subscription === "Period" ? "Periodic" : subscription
				} returns are filed for past years or months,
				<br />
				not for the current year or month.</p>
			<br />
			<div
				className={style.radio__button_div}
				style={{ marginTop: "20px" }}
			>
				<span className={styles.input__label}>
					Type of Filing
				</span>
				<div>
					<div>
						<input
							id="yearlySchedule"
							type="radio"
							value="Yearly"
							checked={subscription === "Yearly"}
							onChange={(e) => {
								setSubcription(e.target.value);
								clearState();
							}}
						/>
						<label
							className={styles.input__label}
							htmlFor="yearlySchedule"
						>
							Yearly
						</label>
					</div>
					<div>
						<input
							id="monthlySchedule"
							type="radio"
							value="Monthly"
							checked={subscription === "Monthly"}
							onChange={(e) => {
								setSubcription(e.target.value);
								clearState();
							}}
						/>
						<label
							className={styles.input__label}
							htmlFor="monthlySchedule"
						>
							Monthly
						</label>
					</div>
					<div>
						<input
							id="periodSchedule"
							type="radio"
							value="Period"
							checked={subscription === "Period"}
							onChange={(e) => {
								setSubcription(e.target.value);
								clearState();
							}}
						/>
						<label
							className={styles.input__label}
							htmlFor="periodSchedule"
						>
							Period
						</label>
					</div>
				</div>
			</div>
			<br />

			{
				subscription === "Yearly" &&
				<>
					<div className={styles.double__inputs}>
						<div>
							<label className={styles.input__label} htmlFor="esbn">
								ESBN *
							</label>
							<input
								type="text"
								name="esbn"
								onChange={(e) => {
									handleProfileInArrayChange(e);
								}}
								id="esbn"
								placeholder=""
								required
								value={formData.esbn}
							/>
						</div>
					</div>
					<br />
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="yearOfReturns"
							>
								Year of Returns
							</label>
							<select
								id="yearOfReturns"
								name="yearOfReturns"
								onChange={(e) => {
									handleProfileInArrayChange(e);
								}}
								value={formData.yearOfReturns}
								required
							>
								{years.map((year, index) => (
									<option key={`${year}${index}`}>{year}</option>
								))}
							</select>
						</div>
					</div>
					<br />
					<br />
					<footer className={style.form__footer}>
						<button
							disabled={
								loading
								|| formData.esbn === ""
								|| formData.yearOfReturns === ""
							}
							type="submit"
							className={[
								"primary__btn",
								styles.primary__button,
								loading ? styles.loading__button : ""
							].join(" ")}
						>
							Continue
						</button>
					</footer>
				</>
			}
			{
				subscription === "Monthly" &&
				<>
					<div className={styles.double__inputs}>
						<div>
							<label className={styles.input__label} htmlFor="esbn">
								ESBN *
							</label>
							<input
								type="text"
								name="esbn"
								onChange={(e) => {
									handleProfileInArrayChange(e);
								}}
								id="esbn"
								placeholder=""
								required
								value={formData.esbn}
							/>
						</div>
					</div>
					<br />
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="yearOfReturns"
							>
								Year of Returns
							</label>
							<select
								id="yearOfReturns"
								name="yearOfReturns"
								onChange={(e) => {
									handleProfileInArrayChange(e);
								}}
								value={formData.yearOfReturns}
								required
							>
								{years.map((year, index) => (
									<option key={`${year}${index}`}>{year}</option>
								))}
							</select>
						</div>
					</div>
					<br />
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="monthOfReturns"
							>
								Month of Returns
							</label>
							<select
								id="monthOfReturns"
								name="monthOfReturns"
								onChange={(e) => {
									handleProfileInArrayChange(e);
								}}
								value={formData.monthOfReturns}
								required
							>
								<option value=''>--Select Month--</option>

								{months.map((year, index) => (
									<option key={`${year}${index}`}>{year}</option>
								))}
							</select>
						</div>
					</div>
					<br />
					<br />
					<footer className={style.form__footer}>
						<button
							disabled={
								loading
								|| formData.esbn === ""
								|| formData.yearOfReturns === ""
								|| formData.monthOfReturns === ""
							}
							type="submit"
							className={[
								"primary__btn",
								styles.primary__button,
								loading ? styles.loading__button : ""
							].join(" ")}
						>
							Continue
						</button>
					</footer>
				</>
			}
			{
				subscription === "Period" &&
				<>
					<div className={styles.double__inputs}>
						<div>
							<label className={styles.input__label} htmlFor="esbn">
								ESBN *
							</label>
							<input
								type="text"
								name="esbn"
								onChange={(e) => {
									handleProfileInArrayChange(e);
								}}
								id="esbn"
								placeholder=""
								required
								value={formData.esbn}
							/>
						</div>
					</div>
					<br />
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="yearOfReturns"
							>
								Year of Returns
							</label>
							<select
								id="yearOfReturns"
								name="yearOfReturns"
								onChange={(e) => {
									handleProfileInArrayChange(e);
								}}
								value={formData.yearOfReturns}
								required
							>
								{years.map((year, index) => (
									<option key={`${year}${index}`}>{year}</option>
								))}
							</select>
						</div>
					</div>
					<br />
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="startMonthOfReturns"
							>
								Start Month of Returns
							</label>
							<input
								type="date"
								name="startMonthOfReturns"
								onChange={(e) => {
									handleProfileInArrayChange(e);
								}}
								id="startMonthOfReturns"
								placeholder=""
								required
								value={formData.startMonthOfReturns}
							/>
						</div>
					</div>
					<br />
					<div className={styles.double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="monthOfReturns"
							>
								End Month of Returns
							</label>
							<input
								type="date"
								name="endMonthOfReturns"
								min={formData.startMonthOfReturns}
								onChange={(e) => {
									handleProfileInArrayChange(e);
								}}
								id="endMonthOfReturns"
								placeholder=""
								required
								value={formData.endMonthOfReturns}
							/>
						</div>
					</div>
					<br />
					<br />
					<footer className={style.form__footer}>
						<button
							disabled={
								loading
								|| formData.esbn === ""
								|| formData.yearOfReturns === ""
								|| formData.startMonthOfReturns === ""
								|| formData.endMonthOfReturns === ""
							}
							type="submit"
							className={[
								"primary__btn",
								styles.primary__button,
								loading ? styles.loading__button : ""
							].join(" ")}
						>
							Continue
						</button>
					</footer>
				</>
			}
		</form>
	);
};
