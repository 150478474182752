import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { formatAmount, getStatusText } from "../../../utils";
import BackButton from "../../../components/goBackButton";
import Table from "../../../components/Table";
import ApproveRejectWidget from "../../../components/ApproveRejectWidget";
import Alert from "../../../components/Alert";
import styles from "../style.module.css";

const CouponGenerationView = () => {
	const { state: locationState } = useLocation();
	const history = useHistory();

	const [rejectReason, setRejectReason] = useState("");
	const [rejecting, setRejecting] = useState(false);
	const [loading, setLoading] = useState(false);
	const [batch, setBatch] = useState([]);
	const [total, setTotal] = useState(null);

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const headers = [
		["S/N", "SN"],
		["Batch Number", "tagid"],
		["Coupons per Batch", "totaltag"],
		[
			"Total Amount per Batch",
			"totalcouponamt",
			(amount) => `₦ ${formatAmount(amount)}`
		]
	];

	const getBatch =
		({ currentPage = 1, pageSize = 20 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);

			let query = `page=${currentPage}&pageSize=${pageSize}&`;

			try {
				let { items, pagination } = await axios
					.get(
						`/getglobalcouponserials/${locationState?.couponglobalid}?${query}`,
						{
							headers: {
								secureddata: "VGhpc2lzTm9ybGljcw=="
							}
						}
					)
					.then((res) => res.data.data);
				setBatch(
					items.map((item, i) => ({
						...item,
						SN:
							(i + 1).toString().length < 2
								? `0${i + 1}.`
								: `${i + 1}.`
					}))
				);
				setTotal(pagination?.totalItems);
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setLoading(false);
			}
		};

	const cancel = async (e) => {
		e.preventDefault();

		try {
			setRejecting(true);

			const req = await axios
				.post("/processcoupon", {
					billno: locationState.couponglobalid,
					approve: "1",
					reason4Reject: rejectReason,
					recId: locationState?.id
				})
				.then((res) => res.data || {});

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: req.msg
			});

			setTimeout(() => history.goBack(), 3000);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRejecting(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	useEffect(() => {
		getBatch()();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<BackButton />
			<div className={styles.coupon__details}>
				<div>
					<span className={styles.key}>Coupon ID: </span>
					<span className={styles.value}>
						{locationState?.couponglobalid}
					</span>
				</div>
				<div>
					<span className={styles.key}>Coupon Amount: </span>
					<span className={styles.value}>
						₦ {formatAmount(locationState?.totalcouponamt)}
					</span>
				</div>
				<div>
					<span className={styles.key}>Generated By: </span>
					<span className={styles.value}>
						{locationState?.inputterid}
					</span>
				</div>
				<div>
					<span className={styles.key}>Status: </span>
					<span
						className={`${styles.value} ${
							getStatusText(
								locationState?.approveflg
							).toLowerCase() === "pending"
								? styles.pending
								: getStatusText(
											locationState?.approveflg
									  ).toLowerCase() === "pending approval"
									? styles.pending__approval
									: getStatusText(
												locationState?.approveflg
										  ).toLowerCase() === "approved"
										? styles.approved
										: styles.denied
						}`}
					>
						{getStatusText(locationState?.approveflg)}
					</span>
				</div>
				<div>
					<span className={styles.key}>Date Issued: </span>
					<span className={styles.value}>
						{new Date(locationState?.createdate).toDateString()}
					</span>
				</div>
			</div>
			<h2 className={styles.table__header}>Serial Number Details</h2>
			{loading ? (
				<p style={{ textAlign: "center" }}>Loading...</p>
			) : (
				<>
					<Table
						headers={headers}
						data={batch}
						pageSize={20}
						total={total}
					/>
					{locationState.approveflg === "0" && (
						<ApproveRejectWidget
							type={1}
							processingNegative={rejecting}
							negativeIdiotProofQuestion={`"${locationState?.couponglobalid}" Global Coupon ID`}
							negativeReasonMessage={`You will be cancelling “${locationState?.couponglobalid}” Global Coupon ID, an email would be sent to notify them accordingly.`}
							negativeReason={rejectReason}
							setNegativeReason={setRejectReason}
							negativeFunction={cancel}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default CouponGenerationView;
