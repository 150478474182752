import React, { useState, useEffect } from "react";
import styles from "./style.module.css";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
	let i = from;
	const range = [];

	while (i <= to) {
		range.push(i);
		i += step;
	}

	return range;
};

const Pagination = ({
	totalRecords = null,
	pageLimit = 20,
	pageNeighbours = 0,
	onPageChanged = (f) => () => f
}) => {
	const [currentPage, setCurrentPage] = useState(1);
	const _pageLimit = typeof pageLimit === "number" ? pageLimit : 30;
	const _totalRecords = typeof totalRecords === "number" ? totalRecords : 0;
	const totalPages = Math.ceil(_totalRecords / _pageLimit);
	const _pageNeighbours =
		typeof pageNeighbours === "number"
			? Math.max(0, Math.min(pageNeighbours, 2))
			: 0;

	useEffect(() => {
		gotoPage(1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const paginationData = {
			currentPage,
			totalPages: totalPages,
			_pageLimit: _pageLimit,
			_totalRecords: _totalRecords
		};

		onPageChanged(paginationData)();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, totalPages, _pageLimit, _totalRecords]);

	const gotoPage = (page) => {
		const currentPage = Math.max(0, Math.min(page, totalPages));

		setCurrentPage(currentPage);
	};

	const handleClick = (page) => (evt) => {
		evt.preventDefault();
		gotoPage(page);
	};

	const handleMoveLeft = (evt) => {
		evt.preventDefault();
		gotoPage(currentPage - _pageNeighbours * 2 - 1);
	};

	const handleMoveRight = (evt) => {
		evt.preventDefault();
		gotoPage(currentPage + _pageNeighbours * 2 + 1);
	};

	/**
	 * Let's say we have 10 pages and we set _pageNeighbours to 2
	 * Given that the current page is 6
	 * The pagination control will look like the following:
	 *
	 * (1) < {4 5} [6] {7 8} > (10)
	 *
	 * (x) => terminal pages: first and last page(always visible)
	 * [x] => represents current page
	 * {...x} => represents page neighbours
	 */
	const pages = (function () {
		/**
		 * totalNumbers: the total page numbers to show on the control
		 * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
		 */
		const totalNumbers = _pageNeighbours * 2 + 3;
		const totalBlocks = totalNumbers + 2;

		if (totalPages > totalBlocks) {
			const startPage = Math.max(2, currentPage - _pageNeighbours);
			const endPage = Math.min(
				totalPages - 1,
				currentPage + _pageNeighbours
			);

			let pages = range(startPage, endPage);

			/**
			 * hasLeftSpill: has hidden pages to the left
			 * hasRightSpill: has hidden pages to the right
			 * spillOffset: number of hidden pages either to the left or to the right
			 */
			const hasLeftSpill = startPage > 2;
			const hasRightSpill = totalPages - endPage > 1;
			const spillOffset = totalNumbers - (pages.length + 1);

			switch (true) {
				// handle: (1) < {5 6} [7] {8 9} (10)
				case hasLeftSpill && !hasRightSpill: {
					const extraPages = range(
						startPage - spillOffset,
						startPage - 1
					);
					pages = [LEFT_PAGE, ...extraPages, ...pages];
					break;
				}

				// handle: (1) {2 3} [4] {5 6} > (10)
				case !hasLeftSpill && hasRightSpill: {
					const extraPages = range(
						endPage + 1,
						endPage + spillOffset
					);
					pages = [...pages, ...extraPages, RIGHT_PAGE];
					break;
				}

				// handle: (1) < {4 5} [6] {7 8} > (10)
				case hasLeftSpill && hasRightSpill:
				default: {
					pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
					break;
				}
			}

			return [1, ...pages, totalPages];
		}

		return range(1, totalPages);
	})();

	if (!_totalRecords || totalPages === 1) return null;

	return (
		<nav aria-label="Table Pagination">
			<ul className={styles.pagination}>
				{pages?.map((page, i) => {
					if (page === LEFT_PAGE)
						return (
							<button
								key={i}
								className={[
									styles.icon__button,
									styles.arrow__left
								].join(" ")}
								aria-label="Previous"
								onClick={handleMoveLeft}
							></button>
						);

					if (page === RIGHT_PAGE)
						return (
							<button
								key={i}
								className={[
									styles.icon__button,
									styles.arrow__right
								].join(" ")}
								aria-label="Next"
								onClick={handleMoveRight}
							></button>
						);

					return (
						<button
							key={i}
							className={[
								styles.icon__button,
								currentPage === page ? styles.active : ""
							].join(" ")}
							onClick={handleClick(page)}
						>
							{page}
						</button>
					);
				})}
			</ul>
		</nav>
	);
};

export default Pagination;
