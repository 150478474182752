import { useState, useEffect, createRef } from "react";
import { useHistory, useLocation } from "react-router";
import axios from "axios";
import { formatDate, formatAmount } from "../../../utils";
import Table from "../../../components/Table";
import Alert from "../../../components/Alert";
import filterStyles from "../../MDAs/style.module.css";

const SoldCouponRecords = () => {
	const { state: locationState } = useLocation();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [used, setUsed] = useState("");
	const [tag, setTag] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const filterRef = createRef();

	const headers = [
		["Batch Number", "TagId"],
		["Inventory ID", "InventoryId"],
		["Global Coupon Count", "GlobalCouponCount"],
		["Purchased Date", "PurchasedDate", formatDate],
		["Total Amount", "TotalAmount", formatAmount],
		["Total Commission", "TotalCommission", formatAmount],
		["Vendor Name", "VendorName"],
		["Vendor Email", "VendorEmail"],
		["Vendor Phone", "VendorFone"],
		[("Total Coupon Used", "TotalCouponUsed")],
		["Total Coupon Not Used", "TotalCouponNotUsed"],
		["Used", "UsedFlg", (flag) => `${Boolean(flag)}`],
		["Used Date", "UsedDate", (date) => (date ? formatDate(date) : "N/A")]
	];

	const getCoupons =
		({ currentPage = 1, pageSize = 20, download = false } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (
				!filterRef.current?.classList.contains(filterStyles.no__display)
			) {
				filterRef.current?.classList.toggle(filterStyles.no__display);
			}

			let query = `page=${currentPage}&pageSize=${pageSize}&`;

			if (startDate) {
				query += `startdate=${startDate}&`;
			}

			if (endDate) {
				query += `enddate=${endDate}&`;
			}

			if (minAmount) {
				query += `minAmount=${minAmount}&`;
			}

			if (maxAmount) {
				query += `maxAmount=${maxAmount}&`;
			}

			if (tag) {
				query += `tag=${tag}&`;
			}

			if (used) {
				query += `usedflg=${used}&`;
			}

			try {
				let { data, total } = await axios
					.get(`/getpurchasecoupons?${query}`, {
						headers: {
							secureddata: "VGhpc2lzTm9ybGljcw=="
						}
					})
					.then((res) => res.data.data);
				setData(data);
				setTotal(total);

				return data;
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0] ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message ||
							error.response?.data?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		getCoupons()();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clearFilters = () => {
		setMinAmount("");
		setMaxAmount("");
		setStartDate("");
		setEndDate("");
		setTag("");
		setUsed("");

		filterRef.current.classList.toggle(filterStyles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getCoupons()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	useEffect(() => {
		if (locationState?.paymentSuccessful)
			setAlert({
				showing: true,
				type: "success",
				message: "You have successfully purchased coupon bulk"
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={filterStyles.actions__div}>
				<button
					className="primary__btn"
					style={{ padding: "0 16px" }}
					onClick={() => history.push("/coupon/purchase")}
				>
					Buy Coupon
				</button>
				<div className={filterStyles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								filterStyles.no__display
							);
						}}
						className={filterStyles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							filterStyles.filter__div,
							filterStyles.no__display
						].join(" ")}
					>
						<div className={filterStyles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										filterStyles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={filterStyles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!startDate &&
								!endDate &&
								!tag &&
								!used &&
								!minAmount &&
								!maxAmount
							}
						>
							Clear Filter fields
						</button>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="tag">Batch Number</label>
								<input
									name="tag"
									id="tag"
									type="text"
									value={tag}
									onChange={(e) => setTag(e.target.value)}
									placeholder="Enter batch number"
								/>
							</div>
						</form>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="used">Used</label>
								<select
									name="used"
									id="used"
									value={used}
									onChange={(e) => setUsed(e.target.value)}
								>
									<option value="">Select</option>
									<option value="true">True</option>
									<option value="false">False</option>
								</select>
							</div>
						</form>
						<p>By Serial Number Amount</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="minAmount">Min Amount</label>
								<input
									name="minAmount"
									id="minAmount"
									type="number"
									value={minAmount}
									onChange={(e) =>
										setMinAmount(e.target.value)
									}
									placeholder="Enter minimum amount"
								/>
							</div>
							<div>
								<label htmlFor="maxAmount">Max Amount</label>
								<input
									name="maxAmount"
									id="maxAmount"
									type="number"
									value={maxAmount}
									onChange={(e) =>
										setMaxAmount(e.target.value)
									}
									placeholder="Enter maximum amount"
								/>
							</div>
						</form>
						<p>By Issue Date</p>
						<form onSubmit={(e) => e.preventDefault()}>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								filterStyles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getCoupons()}
							disabled={
								!startDate &&
								!endDate &&
								!tag &&
								!used &&
								!minAmount &&
								!maxAmount
							}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			{loading && !data ? (
				<p style={{ textAlign: "center" }}>Loading...</p>
			) : (
				<Table
					headers={headers}
					data={data}
					full
					pageSize={20}
					total={total}
					onPageChanged={getCoupons}
				/>
			)}
		</section>
	);
};

export default SoldCouponRecords;
