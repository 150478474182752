import React from "react";
import styles3 from "../../Identity-management-individual/style.module.css";
import styles from "../../Identity-management-individual/style.module.css";
import styles1 from "./style.module.css";
import styles2 from "../../Direct-assessment/Create-direct-assessment/style.module.css";

const Createnewhaven = () => {
	return (
		<>
			<div>
				<div className={styles2.radio__button_div}>
					<span className={styles1.input__label}>
						Bill is Scheduled for
					</span>
					<div>
						<div>
							<input
								id="yearlySchedule"
								type="radio"
								value="yearly"
								// checked={subscription === "yearly"}
								onChange={(e) => {
									""(e.target.value);
								}}
							/>
							<label
								className={styles3.input__label}
								htmlFor="yearlySchedule"
							>
								Yearly
							</label>
						</div>
						<div>
							<input
								id="monthlySchedule"
								type="radio"
								value="monthly"
								// checked={subscription === "monthly"}
								onChange={(e) => {
									""(e.target.value);
								}}
							/>
							<label
								className={styles3.input__label}
								htmlFor="monthlySchedule"
							>
								Monthly
							</label>
						</div>
					</div>
				</div>
				<br />
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="selectyear"
						>
							Bill Year (Fiscal Year)
						</label>
						<select
							id="selectYear"
							// value={fiscalyr}
							// onChange={(e) => setFiscalYear(e.target.value)}
							// // disabled={historyState?.view}
							// required
						>
							<option value="">Select</option>
							<option>2013</option>
							{new Array(new Date().getFullYear() + 1 - 2013)
								.fill(0)
								.map((_, i) => (
									<option key={i}>{2013 + i + 1}</option>
								))}
						</select>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="selectmonth"
						>
							Bill Month (Fiscal Month)
						</label>
						<select
							id="selectmonth"
							value={""}
							// onChange={(e) => {
							// 	setSelectedMonth(e.target.value);
							// }}
							// disabled={subscription === "yearly"}
						>
							{[...Array(12)].map((_, index) => (
								<option
									key={index}
									value={new Intl.DateTimeFormat("en", {
										month: "long"
									}).format(new Date(2000, index, 1))}
								>
									{new Intl.DateTimeFormat("en", {
										month: "long"
									}).format(new Date(2000, index, 1))}
								</option>
							))}
						</select>
					</div>
				</div>
				<br />
				<div className={styles.double__inputs}>
					<div>
						<label>ESBN</label>
						<input type="text" />
					</div>
					<div>
						<label>
							Shop Owner's Name
							<span style={{ color: "red" }}> *</span>
						</label>
						<input type="text" />
					</div>
				</div>
				<br />
				<div className={styles.double__inputs}>
					<div>
						<label>
							Line of Business
							<span style={{ color: "red" }}> *</span>
						</label>
						<input type="text" />
					</div>
					<div>
						<label>
							Shop Number
							<span style={{ color: "red" }}> *</span>
						</label>
						<input type="text" />
					</div>
				</div>
				<br />
				<div className={styles.double__inputs}>
					<div>
						<label>Phone Number</label>
						<input type="text" />
					</div>
					<div>
						<label>Email</label>
						<input type="text" />
					</div>
				</div>
				<br />
				<hr />
				<br />
				<div className={styles.double__inputs}>
					<div>
						<label>
							Discount
							<span style={{ color: "red" }}> *</span>
						</label>
						<input type="text" />
					</div>
					<div>
						<label>
							Reason for Discount
							<span style={{ color: "red" }}> *</span>
						</label>
						<input type="text" />
					</div>
				</div>
				<br />
				<div className={styles.double__inputs}>
					<div>
						<label>Outstanding</label>
						<input type="text" />
					</div>
					<div>
						<label>Reason for Outstanding</label>
						<input type="text" />
					</div>
				</div>
				<br />
				<div className={styles.double__inputs}>
					<div>
						<label>Further Description</label>
						<input type="text" />
					</div>
				</div>
				<br />
				<hr />
				<br />
				<div className={styles.double__inputs}>
					<div>
						<label>
							Bill Type
							<span style={{ color: "red" }}> *</span>
						</label>
						<select></select>
					</div>
				</div>
				<br />
				<br />
				<div className={styles.double__inputs}>
					<div>
						<label>
							Category
							<span style={{ color: "red" }}> *</span>
						</label>
						<select></select>
					</div>
					<div>
						<label>
							Sub-Category
							<span style={{ color: "red" }}> *</span>
						</label>
						<select></select>
					</div>
				</div>
				<br />
				<div className={styles.double__inputs}>
					<div>
						<label>How many units / Number of Occupants</label>
						<input type="text" />
					</div>
					<div>
						<label>How Many Months</label>
						<input type="text" />
					</div>
				</div>
				<br />
				<div className={styles.double__inputs}>
					<div>
						<label>
							Yearly Tarrifs
							<span style={{ color: "red" }}> *</span>
						</label>
						<input type="text" />
					</div>
					<div>
						<label>
							what Revenue is this bill attached to
							<span style={{ color: "red" }}> *</span>
						</label>
						<select></select>
					</div>
				</div>
				<br />
				<hr />
			</div>
		</>
	);
};

export default Createnewhaven;
