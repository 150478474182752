import React, { createRef, useEffect, useState } from "react";
import arrowRightIcon from "../../assets/arrowRightIcon.svg";
import styles1 from "../../Dashboard/MDAs/style.module.css";
import styles from "../MDAs/style.module.css";
import style from "./style.module.css";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import { useHistory } from "react-router";
import axios from "axios";
import RegularModal from "../../components/Modal/regular";
import downloadIcon from "../../assets/downloadFilingIcon.svg";
import { H1Document } from "./modals/h1Document";
import PaymentModal from "../../components/Modal/payment.modal";
import { formatAmount, formatDate } from "../../utils";

function getStatusText(approveRejectFlag) {
	// 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval; 6: Deleted
	if (approveRejectFlag === "0") {
		return "Pending";
	} else if (approveRejectFlag === "1") {
		return "Cancelled";
	} else if (approveRejectFlag === "2") {
		return "Declined";
	} else if (approveRejectFlag === "3") {
		return "Approved";
	} else if (approveRejectFlag === "4") {
		return "Revoked";
	} else if (approveRejectFlag === "5") {
		return "Pending Approval";
	} else {
		return "Deleted";
	}
}

const headers = [
	["Filed Type", "filingType"],
	["Filed Month", "FilingTypeMonth"],
	["Total Tax", "Total_Tax", formatAmount],
	["Filing Start Date", "periodStart"],
	["Filing End Date", "periodEnd"],
	["Filed Year", "filedYear"],
	["Filed Status", "FilingStatus"],
	["Date Filed", "dateFiled", formatDate],
	["Arrears Bill Number", "ArrearsBillNumber"],
	["Payment Status", "paymentStatus"],
	["Process Status", "approvalStatus", getStatusText],
	["Reason for rejection", "rejectReason"],
	["Inputter", "inputter"]
];

const userData = JSON.parse(localStorage.getItem("user_info"));

export const AnnualReturnsCorporate = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [H1File, setH1File] = useState([]);
	const [total, setTotal] = useState(0);
	const [billNo, setBillNo] = useState(null);
	const [paymentModal, setPaymentModal] = useState(false);
	const [filingMode, setFilingMode] = useState(null);
	const [filters, setFilters] = useState({
		ESBN: "",
		fileYear: "",
		filingStatus: "",
		fileMth: "",
		periodStart: "",
		periodEnd: ""
	});

	const [downloadH1ModalStatus, setDownloadH1ModalStatus] = useState({
		downloading: false,
		showing: false,
		data: null
	});

	const filterRef = createRef();
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const history = useHistory();

	const getH1 = async (annualID, corporateCompanyID) => {
		setLoading(true);
		try {
			const accessToken = localStorage.getItem("access_token");
			let data = await axios
				.get(
					`company/annual-returns/${annualID}/${corporateCompanyID}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data || { data: {} });

			if (!data || !data?.data) {
				// handle errors
				return null;
			}
			if (data) {
				setDownloadH1ModalStatus((h1Modal) => ({
					...h1Modal,
					showing: true,
					data: data
				}));
			}
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response?.data?.message ??
					"H1 Data Fetching Unsuccessful!"
			});
		} finally {
			setLoading(false);
		}
	};

	const printH1File = () => {
		if (H1File) {
			const w = window.open();
			if (w.document) {
				w.document.write(H1File);
				w.document.close();
				w.onload = () => {
					w.focus();
					w.print();
				};

				w.onafterprint = () => {
					w.close();
				};
			} else {
				return;
			}
		} else {
			return;
		}
	};

	const fetchH1 =
		({ currentPage = 1, pageSize = 20 } = {}) =>
			async (e) => {
				e?.preventDefault?.();

				setLoading(true);
				if (!filterRef?.current?.classList?.contains(styles.no__display)) {
					filterRef?.current?.classList?.toggle(styles.no__display);
				}

				let query = `page=${currentPage}&pageSize=${pageSize}&`;

				if (filters.ESBN) {
					query += `eSBN=${filters.ESBN}&`;
				}

				if (filters.fileYear) {
					query += `fileYear=${filters.fileYear}&`;
				}

				if (filters.filingStatus) {
					query += `filingStatus=${filters.filingStatus}&`;
				}

				if (filters.fileMth) {
					query += `fileMth=${filters.fileMth}&`;
				}

				try {
					const accessToken = localStorage.getItem("access_token");
					let data = await axios
						.get(
							`coylandpage/annual-returns/h1filing/${userData?.anssid}?${query}`,
							{
								headers: {
									Authorization: `Bearer ${accessToken}`
								}
							}
						)
						.then((res) => res.data || { data: {} });

					if (!data || !data?.data) {
						return null;
					}
					setData(data?.data?.items);
					setTotal(data?.data?.pagination?.totalItems);
				} catch (error) {
					setAlert({
						showing: true,
						type: "error",
						message:
							error?.response?.data?.message ??
							"Annual Returns Fetching Unsuccessful!"
					});
				} finally {
					setLoading(false);
				}
			};

	const verifyBillNo = async (data) => {
		if (data?.ArrearsBillNumber) {
			setBillNo(data?.ArrearsBillNumber);
			setPaymentModal(true);
			setFilingMode(data?.ModeOfFiling)
		} else {
			generateBillNo(data);
		}
	}
	const generateBillNo = async (data) => {
		const payload = {
			globId: "8",                                      // Global Ids; Check the description of global APIs.
			name_on_bill: userData?.corpname,                 // Filing Company Name
			esbn_pid_on_bill: userData?.anssid,                 // Filing Company ESBN
			total_current_bill_amount: data?.diff,            // Arrears Amt
			total_outstanding_bill_amount: 0,
			total_discount_bill_amount: 0,
			total_grand_bill_amount: data?.diff,              // Arrears Amt 
			bill_module_id: "8",                              // Global Ids; Check the description of global APIs.
			bill_mda: "38",                                   // Board of Internal Revenue
			bill_lga: "Headquarters",                         // L.G.A
			bill_zone: "Headquarters",                        // Zone
			bill_taxoffice: "Headquarters",                   // Tax Office
			centbilldet: [                                    // Array objects of details that make up Grand Total Amount
				{
					mda: "38",                                // Board of Internal Revenue
					esbn_pid: userData?.anssid,                 // Filing Company ESBN
					esbn_pid_name: userData?.corpname,        // Filing Company Name
					billdescr: "PAYE e-FILLING ARREARS",
					amount: data?.diff,                       // Arrears Amt
					outstandingamount: 0,
					outstandingreason: "",
					revenuecode: "20008001/12010007"          // Revenue code for PAYE. Constant
				}
			],
		}
        console.log({ payload, data, userData })

		try {
			const generatedBill = await axios.post(`gottapayebillnow/${data?.annualReturnsId}`, payload);
			if (generatedBill) {
				setBillNo(generatedBill?.data?.billno);
				setPaymentModal(true);
				setFilingMode(data?.ModeOfFiling)
				setAlert({
					showing: true,
					type: "success",
					message:
						generatedBill?.data?.mdg ??
						generatedBill?.data?.message ??
						"Bill generated successfully!"
				})
			}
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message: error.response.data?.msg ||
					error.response.data?.errors?.[0] ||
					error.response.data?.errors?.[0]?.message ||
					error.response.data?.errors?.details[0]?.message ||
					error.response.message ||
					error.response.data.message
			});
		}
	}

	useEffect(() => {
		fetchH1()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);
	return (
		<>
			{paymentModal &&
				<PaymentModal
					showing={paymentModal}
					billNumber={billNo}
					goBackTo={
						filingMode === '0'
							? '/PAYE-filing-corporate'
							: '/paye-filing/admin-filing-table'
					}
					toggle={(val) => setPaymentModal(val)}
				/>
			}
			<div className={styles1.MDA__page}>
				{alert.showing && <Alert text={alert.message} type={alert.type} />}
				{downloadH1ModalStatus.showing && (
					<RegularModal showing={downloadH1ModalStatus.showing}>
						<H1Document
							data={downloadH1ModalStatus?.data}
							getFile={(file) => setH1File(file)}
						/>
						<div className={style.action__btns}>
							<button
								className={`btn btn-danger`}
								onClick={() =>
									setDownloadH1ModalStatus((h1Modal) => ({
										...h1Modal,
										showing: false,
										data: {}
									}))
								}
							>
								<p>Close Modal</p>
							</button>
							<button
								className={`btn btn-success ${style.download__btn}`}
								onClick={() => printH1File()}
							>
								<p>Download H1 Details</p>
								<img src={downloadIcon} alt="download" />
							</button>
						</div>
					</RegularModal>
				)}
				<div className={style.flex__betweeen}>
					{/* <h2>Find Annual Returns</h2> */}
					<button
						className={`btn btn-success ${style.download__btn}`}
						onClick={() => history.push("/file-annual-returns-period")}
					>
						<p>File Returns</p>
						<img src={arrowRightIcon} alt="download" />
					</button>
				</div>
				<div className={styles.actions__div} style={{ justifyContent: 'flex-end' }}>
					<div className={styles.filter}>
						<button
							onClick={() => {
								filterRef.current.classList.toggle(
									styles.no__display
								);
							}}
							className={styles.filter__button}
						>
							Filter
						</button>
						<div
							ref={filterRef}
							className={[
								styles.filter__div,
								styles.no__display
							].join(" ")}
						>
							<div className={styles.filter__header}>
								<h6>Filter</h6>
								<button
									aria-labelledby="close filter button"
									onClick={() => {
										filterRef.current.classList.toggle(
											styles.no__display
										);
									}}
								>
									X
								</button>
							</div>
							<button
								className={styles.clear__filter_fields}
								disabled={true}
							>
								Clear Filter fields
							</button>
							<p>By Code</p>
							<form>
								<div>
									<label htmlFor="owneresbn">Esbn</label>
									<input
										name="owneresbn"
										id="owneresbn"
										type="text"
										value={filters.ESBN}
										onChange={(e) =>
											setFilters(() => ({
												...filters,
												ESBN: e.target.value
											}))
										}
										placeholder="Esbn"
									/>
								</div>
							</form>
							<p>By Filing Details</p>
							<form>
								<div>
									<label htmlFor="fileYear">Filed Year</label>
									<input
										name="fileYear"
										id="fileYear"
										type="text"
										value={filters.fileYear}
										onChange={(e) =>
											setFilters(() => ({
												...filters,
												fileYear: e.target.value
											}))
										}
										placeholder="Eg 22/12/1990"
									/>
								</div>
								<div>
									<label htmlFor="filingStatus">
										Filing Status
									</label>
									<input
										name="filingStatus"
										id="filingStatus"
										type="text"
										value={filters.filingStatus}
										onChange={(e) =>
											setFilters(() => ({
												...filters,
												filingStatus: e.target.value
											}))
										}
									/>
								</div>
								<div>
									<label htmlFor="fileMth">Filing Month</label>
									<input
										name="fileMth"
										id="fileMth"
										type="text"
										value={filters.fileMth}
										onChange={(e) =>
											setFilters(() => ({
												...filters,
												fileMth: e.target.value
											}))
										}
									/>
								</div>
							</form>
							<p>By Creation Date</p>
							<form>
								<div>
									<label htmlFor="periodStart">Start Date</label>
									<input
										name="periodStart"
										id="periodStart"
										type="date"
										value={""}
										onChange={(e) =>
											setFilters(() => ({
												...filters,
												periodStart: e.target.value
											}))
										}
									/>
								</div>
								<div>
									<label htmlFor="periodEnd">End Date</label>
									<input
										name="periodEnd"
										id="periodEnd"
										type="date"
										value={""}
										onChange={(e) =>
											setFilters(() => ({
												...filters,
												periodEnd: e.target.value
											}))
										}
									/>
								</div>
							</form>
							<button
								className={[
									styles.submit__button,
									"primary__btn"
								].join(" ")}
								onClick={fetchH1()}
							>
								Search
							</button>
						</div>
					</div>
				</div>
				<div className={styles.table__div}>
					{loading && !data ? (
						<p style={{ textAlign: "center" }}>Loading...</p>
					) : (
						<Table
							headers={headers}
							data={data}
							full
							pageSize={20}
							total={total}
							onRowClick={() => null}
							onView={(data) => {
								history.push(`/paye-filing/corporate-view`, {
									coy: data?.annualReturnsId,
									corporateCompanyID: data.corporatecoyfoldid,
									viewer: 'self-service'
								});
							}}
							onH1={(data) => {
								getH1(
									data?.annualReturnsId,
									data?.corporatecoyfoldid,
								);
							}}
							onPayNow={(data) => verifyBillNo(data)}
						/>
					)}
				</div>
			</div>
		</>
	);
};
