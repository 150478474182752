import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import Alert from "../../components/Alert";
import formStyles from "../../Identity-management-individual/style.module.css";

const ChangePassword = () => {
	const history = useHistory();

	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const changePassword = async (e) => {
		e?.preventDefault();
		setLoading(true);

		try {
			const res = await axios
				.post(`/auth/change-password`, {
					oldPassword,
					newPassword
				})
				.then((res) => res.data);

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.message
			});
			setTimeout(() => history.push("/"), 3000);
		} catch (error) {
			if (error.response) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message ||
						error.response?.data.err.message
				}));
			} else if (error.request) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			} else {
				console.log("Error", error.message);
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			5000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<form onSubmit={changePassword} className={formStyles.columned__form}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<div className={formStyles.double__inputs}>
				<div>
					<label htmlFor="oldPassword">
						Old Password <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="password"
						id="oldPassword"
						value={oldPassword}
						onChange={(e) => setOldPassword(e.target.value)}
						placeholder="***********"
						required
					/>
				</div>
				<hr className={formStyles.divider} />
				<div>
					<label htmlFor="newPassword">
						New Password <span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="password"
						id="newPassword"
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
						placeholder="************"
						required
					/>
				</div>
				<div>
					<label htmlFor="confirmNewPassword">
						Confirm New Password{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						type="password"
						id="confirmNewPassword"
						value={confirmNewPassword}
						onChange={(e) => setConfirmNewPassword(e.target.value)}
						placeholder="************"
						required
					/>
				</div>
			</div>
			<button
				type="submit"
				disabled={
					!oldPassword ||
					!newPassword ||
					loading ||
					newPassword !== confirmNewPassword
				}
				className={"primary__btn"}
			>
				{loading ? "Changing Password..." : "Change Password"}
			</button>
		</form>
	);
};

export default ChangePassword;
