import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import Aside from "../../components/Aside";
import Alert from "../../components/Alert";
import Logo from "../../assets/logo.svg";
import GoHomeIcon from "../../assets/goToLinkIcon.svg";
import styles from "./signup.module.css";

const SignUp = () => {
	const history = useHistory();
	const [NIN, setNIN] = useState("");
	const [firstname, setFirstName] = useState("");
	const [middlename, setMiddleName] = useState("");
	const [lastname, setLastName] = useState("");
	const [corpname, setCorpName] = useState("");
	const [phonenumber, setPhonenumber] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [tin, setTIN] = useState("");
	const [checkingTINRecord, setCheckingTINRecord] = useState(false);
	const [tinError, setTinError] = useState("");
	const [currentTab, setCurrentTab] = useState("individual");

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	const [fetching, setFetching] = useState(false);

	const jtbChecks = async () => {
		setCheckingTINRecord(true);

		let endpoint =
			currentTab === "individual"
				? "/txn/jtb/verifyInd"
				: "/txn/jtb/verifyBiz";
		try {
			await axios
				.post(endpoint, {
					tin,
					userId: ""
				})
				.then((res) => {
					if (
						res?.data?.ResponseCode === "004" ||
						res?.data?.ResponseCode === "005"
					) {
						setTinError("TIN " + res?.data?.ResponseDescription);
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message: `TIN ${res?.data?.ResponseDescription}. Please correct.`
						});
					} else if (res?.data?.ResponseCode === "001") {
						setTinError("");
						setAlert({
							...alert,
							showing: true,
							type: "success",
							message: "TIN record found"
						});
						if (currentTab === "individual") {
							setFirstName(res?.data?.Taxpayer?.first_name);
							setMiddleName(res?.data?.Taxpayer?.middle_name);
							setLastName(res?.data?.Taxpayer?.last_name);
						} else {
							setCorpName(res?.data?.Taxpayer?.registered_name);
						}
						setPhonenumber(res?.data?.Taxpayer?.phone_no);
						setEmail(res?.data?.Taxpayer?.email);
					}
				});
		} catch (err) {
			let message = "";
			if (err.response) {
				message =
					err.response.data.errors?.details[0].message ||
					err.response.data?.error ||
					err.response.data?.errors ||
					err.response.data?.msg;
			} else {
				message = err.message;
			}
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message:
					message ||
					"Something went wrong. Unable to submit your request."
			});
		} finally {
			setCheckingTINRecord(false);
		}
	};

	const signup = async (e) => {
		e.preventDefault();
		setFetching(true);

		if (password !== confirmPassword) {
			alert("Password mismatch.");
			return;
		}

		if (tinError !== "") {
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message: tinError
			});
		}

		try {
			const res = await axios
				.post(`/auth/signup`, {
					firstname: currentTab === "corporate" ? " " : firstname,
					middlename: currentTab === "corporate" ? " " : middlename,
					lastname: currentTab === "corporate" ? " " : lastname,
					phonenumber,
					corpname: currentTab === "corporate" ? corpname : "",
					tin,
					nin: currentTab === "individual" ? NIN : "",
					email,
					password,
					accounttype: currentTab
				})
				.then((res) => res.data);

			if (!res.data) throw new Error("An error has occurred");

			if (currentTab === "corporate") {
				localStorage.setItem("access_token", res.data.access_token);
				localStorage.setItem("id", res.data.id);
				localStorage.setItem(
					"user_info",
					JSON.stringify({ ...res.data, access_token: undefined })
				);
			}
			history.push("/otp-phone", {
				signUpId: res.data.id,
				accountType: currentTab
			});
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setFetching(false);
		}
	};

	useEffect(() => {
		if (tin.length >= 10) jtbChecks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tin]);

	useEffect(() => {
		let delay;

		if (alert.type === "success") {
			delay = setTimeout(
				() =>
					setAlert({
						showing: false,
						type: null,
						message: ""
					}),
				3000
			);
		}

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<section className={styles.signup_page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<Aside showOnMobile={false} />
			<main className={styles.auth__form__container}>
				<Link to="/home">
					Go back to Home <img src={GoHomeIcon} alt="go home icon" />
				</Link>
				<img src={Logo} alt="logo" />
				<h1 className={styles.heading__text}>
					{currentTab === "individual" ? "Individual" : "Corporate"}{" "}
					Sign Up
				</h1>
				<p className={styles.sub__heading__text}>
					Already have an account? <Link to="/login">Log in</Link>
				</p>
				<nav className={styles.tabs}>
					<button
						className={
							currentTab === "individual"
								? styles.active_tab
								: null
						}
						onClick={() => {
							if (currentTab === "corporate") {
								setCurrentTab("individual");
								if (tin) setTIN("");
							}
						}}
						disabled={checkingTINRecord}
					>
						Individual
					</button>
					<button
						className={
							currentTab === "corporate"
								? styles.active_tab
								: null
						}
						onClick={() => {
							if (currentTab === "individual") {
								setCurrentTab("corporate");
								if (tin) setTIN("");
							}
						}}
						disabled={checkingTINRecord}
					>
						Corporate
					</button>
				</nav>
				<form className={styles.auth__form} onSubmit={signup}>
					<label htmlFor="tin">
						Tax Identification Number (TIN){" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						id="tin"
						name="tin"
						type="text"
						placeholder="Input your TIN"
						onChange={(e) => {
							setTIN(e.target.value);
							if (!e.target.value && tinError) {
								setTinError("");
								setAlert({
									showing: false,
									type: null,
									message: ""
								});
							}
						}}
						value={tin}
						required
					/>
					{checkingTINRecord && (
						<p
							style={{
								fontSize: "12px",
								margin: "-15px 0 10px",
								color: "#9e9e9e"
							}}
						>
							checking record...
						</p>
					)}
					{currentTab === "corporate" && (
						<>
							<label htmlFor="corpname">
								Company Name{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								id="corpname"
								name="corpname"
								type="text"
								placeholder="John Agu Holdings"
								onChange={(e) => {
									setCorpName(e.target.value);
								}}
								value={corpname}
								required
								disabled={checkingTINRecord}
							/>
						</>
					)}
					{currentTab === "individual" && (
						<>
							<label htmlFor="NIN">
								National Identification Number (NIN)
							</label>
							<input
								id="NIN"
								name="NIN"
								type="number"
								placeholder="937462764738"
								onChange={(e) => {
									setNIN(e.target.value);
								}}
								value={NIN}
							/>
							<label htmlFor="firstname">
								First Name{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								id="firstname"
								name="firstname"
								type="text"
								placeholder="Chinedu"
								onChange={(e) => {
									setFirstName(e.target.value);
								}}
								value={firstname}
								required
								disabled={checkingTINRecord}
							/>
							<label htmlFor="middlename">Middle Name</label>
							<input
								id="middlename"
								name="middlename"
								type="text"
								placeholder="John"
								onChange={(e) => {
									setMiddleName(e.target.value);
								}}
								value={middlename}
								disabled={checkingTINRecord}
							/>
							<label htmlFor="lastname">
								Surname <span style={{ color: "red" }}>*</span>
							</label>
							<input
								id="lastname"
								name="lastname"
								type="text"
								placeholder="Doe"
								onChange={(e) => {
									setLastName(e.target.value);
								}}
								value={lastname}
								required
								disabled={checkingTINRecord}
							/>
						</>
					)}
					<label htmlFor="email">
						Email Address <span style={{ color: "red" }}>*</span>
					</label>
					<input
						id="email"
						name="email"
						type="email"
						placeholder={
							currentTab === "individual"
								? "john.doe@gmail.com"
								: "john.holdings@gmail.com"
						}
						onChange={(e) => {
							setEmail(e.target.value);
						}}
						value={email}
						required
						disabled={checkingTINRecord}
					/>
					<label htmlFor="phonenumber">
						Phone no <span style={{ color: "red" }}>*</span>
					</label>
					<input
						id="phonenumber"
						name="phonenumber"
						type="tel"
						placeholder="+2348012345678"
						onChange={(e) => {
							setPhonenumber(e.target.value);
						}}
						value={phonenumber}
						required
						disabled={checkingTINRecord}
					/>
					<hr className={styles.hr} />
					<label htmlFor="password">
						Password <span style={{ color: "red" }}>*</span>
					</label>
					<input
						id="password"
						name="password"
						type="password"
						placeholder="******"
						// hidden={false}
						onChange={(e) => {
							setPassword(e.target.value);
						}}
						value={password}
						required
					/>
					<label htmlFor="password">
						Confirm Password <span style={{ color: "red" }}>*</span>
					</label>
					<input
						id="confirmPassword"
						name="confirmPassword"
						type="password"
						placeholder="******"
						onChange={(e) => {
							setConfirmPassword(e.target.value);
						}}
						value={confirmPassword}
						required
					/>
					<button
						type="submit"
						className={`${styles.submit__button} primary__btn`}
						disabled={
							!tin ||
							(tin && tinError) ||
							(tin && tin.length < 10) ||
							!email ||
							!phonenumber ||
							!password ||
							!confirmPassword ||
							confirmPassword !== password ||
							fetching
						}
					>
						{fetching ? "Signing up ..." : "Sign Up"}
					</button>
				</form>
			</main>
		</section>
	);
};

export default SignUp;
